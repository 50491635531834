import React from 'react';
import PropTypes from 'prop-types';
// import Image from 'next/image';

import { SmartLink } from '../SmartLink';

import style from './Card.module.scss';

const Card = props => {
    const { title, description, image, cta } = props;

    let newTitle = title;
    if (title.toLowerCase().indexOf('private equity') > -1 && title.match(/^(?!.*<[^>]+>).*/)) {
        const titleArr = title.split(' ');
        if (titleArr.length > 2) {
            const tmpArr = titleArr.map((t, i) => {
                return i === 2 && i === titleArr - 1
                    ? `<span>${t}</span>`
                    : i === 2
                    ? `<span>${t} `
                    : i === titleArr - 1
                    ? `${t}</span>`
                    : `${t} `;
            });
            newTitle = tmpArr.join('');
        }
    }

    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.content}>
                    <div className={style.title}>
                        <h2 dangerouslySetInnerHTML={{ __html: newTitle }} />
                        <div className={style.description} dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                    <div className={style.cta}>
                        <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                            {cta.text}
                        </SmartLink>
                    </div>
                </div>
                <div className={style.image}>
                    <img src={image.url} alt={image.alt} />
                </div>
            </div>
        </div>
    );
};

Card.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.shape({
            target: PropTypes.string,
            url: PropTypes.string,
        }),
    }),
};

export default Card;
