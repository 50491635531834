// import _ from 'lodash';
// import moment from 'moment';

export const reformatGlobal = (data = {}) => {
    return {
        ...data,
        socialMedia: data.footer.footerGroup.socialMedia,
    };
};

export const reformatSeo = (data = {}) => {
    return {
        pageTitle: data.pageTitle,
        pageDescription: data.pageDescription,
        pageUrl: data.pageUrl,
        pageKeywords: data.pageKeywords,
        pageOgTitle: data.pageOgTitle,
        pageOgDescription: data.pageOgDescription,
        pageOgImage: data.pageOgImage,
    };
};

export const reformatHeroBanner = (data = {}) => {
    const item = data.items[0];
    return {
        image: {
            bg: item.image || item.image.url,
            headline: item.headline || data.title,
        },
    };
};

export const reformatTwoColsMedia = (data = {}) => {
    const testimonial = data?.items
        ? data.items.map((i, o) => {
              return {
                  image: {
                      url: i.image.url,
                      alt: i.image.alt,
                  },
                  mobileImage: {
                      url: i.image.url,
                      alt: i.image.alt,
                  },
                  quote: {
                      headline: i.testimonial,
                      title: i.role,
                      name: i.name,
                  },
                  isMediaLeft: o !== 0,
              };
          })
        : '';

    const media = data?.items
        ? ''
        : data.map((i, o) => {
              return {
                  title: i.title,
                  description: i.description,
                  image: {
                      url: i.image.url,
                      alt: i.image.alt,
                  },
                  mobileImage: {
                      url: i.mobileImage.url,
                      alt: i.mobileImage.alt,
                  },
                  poster: i.coverImage.url,
                  video: i.video,
                  isMediaLeft: o % 2 === 0,
              };
          });

    return {
        content: data?.items ? testimonial : media,
    };
};

export const reformatThreeCardsCarouselPopup = (data = {}) => {
    return {
        headline: data.title,
        content: data.items.map(item => {
            return {
                title: item.title,
                desc: item.description,
            };
        }),
        button: {
            text: data.items[0].cta.text,
        },
    };
};

export const reformatNewsDetail = (data = {}) => {
    const info = data?.filter(item => item.type === 'newsDetailInfo');
    const quoteContents = data?.filter(item => item.type === 'newsDetailQuote')[0];
    const downloadContents = data?.filter(item => item.type === 'newsDetailDownloadContent');
    const download = data?.filter(item => item.type === 'newsDetailDownload');
    const contact = data?.filter(item => item.type === 'newsDetailContact');
    const article = data?.filter(item => item.type === 'newsDetailText' || item.type === 'newsDetailImage');
    const video = data?.filter(item => item.type === 'newsDetailVideo')[0];

    return {
        header: {
            photo: {
                url: info[0]?.header.author.image.url,
                alt: '',
            },
            date: info[0]?.header.publishingDate,
            name: info[0]?.header.author.name,
            share: {
                title: 'SHARE',
                items: [
                    // {
                    //     title: 'facebook',
                    //     icon: 'facebook2',
                    // },
                    {
                        title: 'twitter',
                        icon: 'twitter2',
                    },
                    {
                        title: 'linkedin',
                        icon: 'linkedin2',
                    },
                ],
            },
        },
        settings: {
            requireDownloadAcceptTerms: info[0]?.header.requireDownloadAcceptTerms,
            downloadAcceptButtonText: info[0]?.header.downloadAcceptButtonText,
        },
        content: {
            headline: info[0]?.title,
            video: video,
            quoteContents: quoteContents,
            downloadContents: downloadContents,
            article,
            download,
            contact,
        },
    };
};

export const reformatStatementsAndStats = (data = {}) => {
    return {
        title: data.title,
        items: data.statements?.map(item => ({
            text: item.text,
            icon: item.image,
        })),
    };
};

export const reformatCaseStudyDetail = (data = {}) => {
    const info = data?.filter(item => item.type === 'caseStudyInfo');
    const contents = info[0].contents;
    const quoteContents = contents?.filter(item => item.type === 'newsDetailQuote')[0];
    const download = contents?.filter(item => item.type === 'newsDetailDownload');
    const contact = contents?.filter(item => item.type === 'newsDetailContact');
    const article = contents?.filter(item => item.type === 'caseStudyText' || item.type === 'caseStudyImage');
    const video = contents?.filter(item => item.type === 'newsDetailVideo')[0];

    return {
        header: {
            photo: {
                url: info[0]?.header.author.image.url,
                alt: '',
            },
            date: info[0]?.header.publishingDate,
            name: info[0]?.header.author.name,
            share: {
                title: 'SHARE',
                items: [
                    // {
                    //     title: 'facebook',
                    //     icon: 'facebook2',
                    // },
                    {
                        title: 'twitter',
                        icon: 'twitter2',
                    },
                    {
                        title: 'linkedin',
                        icon: 'linkedin2',
                    },
                ],
            },
        },
        settings: {},
        content: {
            headline: info[0]?.title,
            video: video,
            quoteContents: quoteContents,
            article,
            download,
            contact,
        },
    };
};

export const reformatFeatureESGReport = (data = {}) => {
    return {
        ...data,
        item: {
            ...data.featureReport,
            type: data.featureReport.sector,
        },
    };
};

export const reformatFeatureCaseStudy = (data = {}) => {
    return {
        ...data,
        item: {
            ...data.featureItem,
            type: data.featureItem.type,
        },
    };
};
