import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import style from './Disclaimer.module.scss';

const Disclaimer = ({ description }) => {
    return (
        <div className={[style.disclaimer, 'container'].join(' ')}>
            <div className={style.disclaimerDescription}>{parse(description)}</div>
        </div>
    );
};

Disclaimer.propTypes = {
    description: PropTypes.string,
    display: PropTypes.bool,
    referenceNumber: PropTypes.number,
};

export default Disclaimer;
