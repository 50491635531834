export const backgroundColorDefault = {
    backgroundColor: [
        '#E67C25', // color-orange
        '#3A74B4', // color-secondary
        '#585858', // not matched
        '#525180', // color-blue-80
    ]
}

export const backgroundColorTransparent = {
    backgroundColor: [
        'rgba(230, 124, 37, 0.65)', // color-orange
        'rgba(58, 116, 180, 0.65)', // color-secondary
        'rgba(88, 88, 88, 0.65)', // not matched
        'rgba(82, 81, 128, 0.65)', // color-blue-80
    ]
}

export const modifyConfig = (config, configProp, configVal) => {
    switch (configProp) {
        case 'legendoff':
            config.options.plugins.legend.display = false;
        break;
        case 'legendon':
            config.options.plugins.legend.display = true;
        break;
        case 'titleoff':
            config.options.plugins.title.display = false;
        break;
    }
    return config;
}

export const configDefaultBar = {
    type: 'bar',
    options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            title: {
                display: true
            },
        },
        scales: {
            x: {
                display: false
            },
            y: {
                ticks: {
                    color: '#000',
                    font: { size: 16, family: 'Graphik' }
                },
                grid: {
                    display: false
                }
            }
        },
    },
}

export const configDefaultDoughnut = {
    type: 'doughnut',
    options: {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            },
            title: {
                display: true
            },
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    }
}

export const configDefaultPie = {
    type: 'pie',
    options: {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            },
            title: {
                display: true
            },
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    }
}

export const configDefaultLine = {
    type: 'line',
    options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            title: {
                display: true
            },
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    }
}

export const configDefaultPolarArea = {
    type: 'polarArea',
    options: {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'bottom'
            },
            title: {
                display: true
            },
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    }
}
