import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import style from './ProfileDetail.module.scss';

import { SmartLink } from '../SmartLink';
import PeopleList from '../PeopleList';

const ProfileDetail = props => {
    const {
        name,
        role,
        image,
        description,
        linkedIn,
        twitter,
        facebook,
        email,
        // offices,
        // strategy,
        // sectors,
        // teams,
        qualifications,
        relatedInvestments,
        officeAddress,
        phone,
        otherTeamMembers,
        tags,
    } = props;

    // const tags = [];
    // if (offices?.length) {
    //     offices.map(i => tags.push(i));
    // }
    // if (strategy?.length) {
    //     strategy.map(i => tags.push(i));
    // }
    // if (sectors?.length) {
    //     sectors.map(i => tags.push(i));
    // }
    // if (teams?.length) {
    //     teams.map(i => tags.push(i));
    // }

    const imageUrl = image?.url !== '' ? image.url : '/images/default.png';

    return (
        <>
            <div className={[style.el, 'profile-detail'].join(' ')}>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.image}>
                        <img src={imageUrl} alt={image?.alt} />
                    </div>
                    <div className={style.content}>
                        <div className={style.contentWrapper}>
                            <h1 className={style.name}>{name}</h1>
                            <p className={style.role}>{role}</p>
                            <div className={style.social}>
                                <div>
                                    {linkedIn && (
                                        <a
                                            href={linkedIn}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            aria-label='linkedIn'
                                        >
                                            <span className='icon-linkedin2' aria-hidden='true' />
                                        </a>
                                    )}
                                    {twitter && (
                                        <a
                                            href={twitter}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            aria-label='twitter'
                                        >
                                            <span className='icon-twitter2' aria-hidden='true' />
                                        </a>
                                    )}
                                    {facebook && (
                                        <a
                                            href={facebook}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            aria-label='facebook'
                                        >
                                            <span className='icon-facebook2' aria-hidden='true' />
                                        </a>
                                    )}
                                    {email && (
                                        <a
                                            href={`mailto:${email}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            aria-label='email'
                                        >
                                            <span className='icon-email2' aria-hidden='true' />
                                        </a>
                                    )}
                                </div>
                            </div>
                            {tags.length > 0 && (
                                <div className={style.tags}>
                                    {tags.map((tag, i) => (
                                        <div key={i} className={style.tagItem}>
                                            {tag}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div
                                className={style.description}
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                            {qualifications?.body && (
                                <div className={style.contentBlock}>
                                    <h4>{qualifications.title}</h4>
                                    <div
                                        className={style.body}
                                        dangerouslySetInnerHTML={{
                                            __html: qualifications.body,
                                        }}
                                    />
                                </div>
                            )}
                            {relatedInvestments?.data.length > 0 && (
                                <div className={style.contentBlock}>
                                    <h4>{relatedInvestments.title}</h4>
                                    {relatedInvestments.data.map((ri, i) => (
                                        <Fragment key={ri.text}>
                                            <SmartLink href={ri.link.url}>{ri.title}</SmartLink>
                                            {i < relatedInvestments.data.length - 1 ? ', ' : ''}
                                        </Fragment>
                                    ))}
                                </div>
                            )}
                            {officeAddress?.data && (
                                <div className={style.contentBlock}>
                                    <h4>{officeAddress.title}</h4>
                                    <div
                                        className={style.body}
                                        dangerouslySetInnerHTML={{
                                            __html: officeAddress.data,
                                        }}
                                    />
                                </div>
                            )}
                            {phone?.data && (
                                <div className={style.contentBlock}>
                                    <h4>{phone.title}</h4>
                                    <a href={`tel:${phone.data}`}>
                                        <div
                                            className={style.body}
                                            dangerouslySetInnerHTML={{
                                                __html: phone.data,
                                            }}
                                        />
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {otherTeamMembers?.data.length > 0 && <PeopleList title={otherTeamMembers.title} cta={otherTeamMembers.cta} items={otherTeamMembers.data} />}
        </>
    );
};

ProfileDetail.propTypes = {
    name: PropTypes.string,
    role: PropTypes.string,
    description: PropTypes.string,
    linkedIn: PropTypes.string,
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    email: PropTypes.string,
    offices: PropTypes.array,
    strategy: PropTypes.array,
    sectors: PropTypes.array,
    teams: PropTypes.array,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    qualifications: PropTypes.shape({
        title: PropTypes.string,
        body: PropTypes.string,
    }),
    relatedInvestments: PropTypes.shape({
        title: PropTypes.string,
        data: PropTypes.array,
    }),
    officeAddress: PropTypes.shape({
        title: PropTypes.string,
        data: PropTypes.string,
    }),
    phone: PropTypes.shape({
        title: PropTypes.string,
        data: PropTypes.string,
    }),
    otherTeamMembers: PropTypes.shape({
        title: PropTypes.string,
        cta: PropTypes.shape({
            text: PropTypes.string,
            link: PropTypes.object,
        }),
        data: PropTypes.array,
    }),
    tags: PropTypes.array,
};
export default ProfileDetail;

ProfileDetail.defaultProps = {};
