import React from 'react';
import PropTypes from 'prop-types';

import style from './TextOnlyHeader.module.scss';

const TextOnlyHeader = props => {
    const { title, description } = props;

    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <h1 className={style.headline}>{title}</h1>
                <p className={style.desc}>{description}</p>
            </div>
        </div>
    );
};

TextOnlyHeader.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};

export default TextOnlyHeader;
