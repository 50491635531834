import React from 'react';

// components
import Banner from './Banner';
import CommonStaticHeader from './CommonStaticHeader';
import HomeTreasers from './HomeTreasers';
import LatestNews from './LatestNews';
import LifeAtPermira from './LifeAtPermira';
import JumpOffCards from './JumpOffCards';
import RelatedNews from './RelatedNews';
import OurOffices from './OurOffices';
import InnerPageHeader from './InnerPageHeader';
import PeopleListing from './PeopleListing';
import Breadcrumb from './Breadcrumb';
import TwoColsMedia from './TwoColsMedia';
import ThreeCardsCarouselPopup from './ThreeCardsCarouselPopup';
import FourColsInfo from './FourColsInfo';
import FeatureArticle from './FeatureArticle';
import NewsListing from './NewsListing';
import NewsDetail from './NewsDetail';
import ProfileDetail from './ProfileDetail';
import TwoColsDetailsBox from './TwoColsDetailsBox';
import InvestmentDetails from './InvestmentDetails';
import PeopleList from './PeopleList';
import RecentInvestment from './RecentInvestment';
import OfficesTabs from './OfficesTabs';
import ScrollHijackGroup from './ScrollHijackGroup';
import OfficeDetail from './OfficeDetail';
import PortfolioListing from './Portfolio';
import Lifecycle from './Lifecycle';
import ExpandableSection from './ExpandableSection';
import InvResReport from './InvResReport';
import CaseStudyScrollHijack from './CaseStudyScrollHijack';
import ReportListing from './ReportListing';
import Chart from './Chart';
import CharityTeaser from './CharityTeaser';
import CaseStudyListing from './CaseStudyListing';
import TextOnlyHeader from './TextOnlyHeader';
import SponsorList from './SponsorList';
import StaticPageContent from './StaticPageContent';
import SearchResult from './SearchResult';
import Testimonials from './Testimonials';
import TwoColumnsContentBlock from './TwoColumnsContentBlock';
import TwoColumnsContentBlockCarousel from './TwoColumnsContentBlockCarousel';
import TwoColumnsCustomContentBlock from './TwoColumnsCustomContentBlock';
import FullColumnTextBlock from './FullColumnTextBlock';
import Disclaimer from './Disclaimer';
import InTheNewsListing from './InTheNewsListing';
import AccordionList from './AccordionList';
import AccordionIconList from './AccordionIconList';
import AccordionIconItem from './AccordionIconItem';
import Article from './Article';
import TextItem from './TextItem';
import ImageItem from './ImageItem';
import StatsItem from './StatsItem';
import {
    // reformatHeroBanner,
    reformatTwoColsMedia,
    reformatThreeCardsCarouselPopup,
    reformatNewsDetail,
    reformatStatementsAndStats,
    reformatCaseStudyDetail,
    reformatFeatureESGReport,
    reformatFeatureCaseStudy,
} from '../utils/formatData';

export const render = (component, i, components, isPopup, isMobile, googleMapKey) => {
    const key = i;
    let props;
    let Component = null;
    component.popup = isPopup;

    switch (component.type) {
        case 'newsOrInsightsDetailInfo':
            props = {
                ...component,
            };
            Component = Article;
            break;
        case 'heroBanner':
            props = {
                ...component,
                isMobileDevice: isMobile,
            };
            Component = Banner;
            break;
        case 'landingPageHeroBanner':
            props = {
                ...component,
            };
            Component = CommonStaticHeader;
            break;
        case 'homepageTeasers':
            props = {
                ...component,
            };
            Component = HomeTreasers;
            break;
        case 'newsTeaser':
            props = {
                ...component,
            };
            Component = LatestNews;
            break;
        case 'banner':
            props = {
                ...component,
            };
            Component = LifeAtPermira;
            break;
        case 'heroSection':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = InnerPageHeader;
            break;
        case 'pageTeaserGroup':
            props = {
                ...component,
            };
            Component = JumpOffCards;
            break;
        case 'esgReportList':
            props = {
                ...component,
                cardType: 'report',
            };
            Component = RelatedNews;
            break;
        case 'caseStudyTeaserList':
            props = {
                ...component,
                cardType: 'caseStudy',
            };
            Component = RelatedNews;
            break;
        case 'relatedNewsViewsBox':
            props = {
                ...component,
            };
            Component = RelatedNews;
            break;
        case 'peopleListing':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = PeopleListing;
            break;
        case 'breadcrumb':
            props = {
                ...component,
            };
            Component = Breadcrumb;
            break;
        case 'contentBlocks':
            props = {
                ...reformatTwoColsMedia(component.data),
                isMobile: isMobile,
            };
            Component = TwoColsMedia;
            break;
        case 'testimonials':
            props = {
                ...component,
            };
            Component = Testimonials;
            break;
        case 'peopleValues':
            props = {
                ...reformatThreeCardsCarouselPopup(component.data),
            };
            Component = ThreeCardsCarouselPopup;
            break;
        case 'statementsAndStats':
            props = {
                ...reformatStatementsAndStats(component),
            };
            Component = FourColsInfo;
            break;
        case 'ourValues':
            props = {
                ...component.data,
            };
            Component = FourColsInfo;
            break;
        case 'profileDetail':
            props = {
                ...component,
            };
            Component = ProfileDetail;
            break;
        case 'featureCaseStudy':
            props = {
                ...reformatFeatureCaseStudy(component),
            };
            Component = FeatureArticle;
            break;
        case 'featureESGReport':
            props = {
                ...reformatFeatureESGReport(component),
            };
            Component = FeatureArticle;
            break;
        case 'featureArticle':
            props = {
                ...component,
            };
            Component = FeatureArticle;
            break;
        case 'newsListing':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = NewsListing;
            break;
        case 'newsDetailInfo':
            props = {
                ...reformatNewsDetail(components),
            };
            Component = NewsDetail;
            break;
        case 'caseStudyInfo':
            props = {
                ...reformatCaseStudyDetail(components),
            };
            Component = NewsDetail;
            break;
        case 'InvestingCreditContentBlocks':
            props = {
                ...component,
            };
            Component = TwoColsDetailsBox;
            break;
        case 'investingCreditDetail':
            props = {
                ...component,
            };
            Component = InvestmentDetails;
            break;
        case 'peopleList':
            props = {
                ...component,
                compTypes: components.map(c => c.type),
            };
            Component = PeopleList;
            break;
        case 'recentInvestments':
            props = {
                ...component,
            };
            Component = RecentInvestment;
            break;
        case 'contactUs':
            props = {
                ...component,
            };
            Component = OfficesTabs;
            break;
        case 'scrollHijackGroup':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = ScrollHijackGroup;
            break;
        case 'officeDetail':
            props = {
                ...component,
                googleMapKey,
            };
            Component = OfficeDetail;
            break;
        case 'relatedOffices':
            props = {
                relatedOffices: true,
                contents: {
                    title: component.title,
                    regions: [
                        {
                            offices: component.offices,
                        },
                    ],
                },
            };
            Component = OurOffices;
            break;
        case 'PortfolioListing':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = PortfolioListing;
            break;
        case 'lifecycle':
            props = {
                ...component,
            };
            Component = Lifecycle;
            break;
        case 'investmentResponsibilityText':
        case 'investmentResponsibilityReport':
            props = {
                ...component,
            };
            Component = InvResReport;
            break;
        case 'portfolioBasicInfo':
            props = {
                ...component,
            };
            Component = CaseStudyScrollHijack;
            break;
        case 'portfolioFeatureItem':
            props = {
                contents: [
                    {
                        ...component,
                        description: component.content,
                    },
                ],
            };
            Component = TwoColumnsContentBlock;
            break;
        case 'reportListing':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = ReportListing;
            break;
        case 'charityTeaser':
            props = {
                ...component,
            };
            Component = CharityTeaser;
            break;
        case 'CaseStudyListing':
            props = {
                ...component,
                isMobile: isMobile,
            };
            Component = CaseStudyListing;
            break;
        case 'header':
            props = {
                ...component,
            };
            Component = TextOnlyHeader;
            break;
        case 'sponsorList':
            props = {
                ...component,
            };
            Component = SponsorList;
            break;
        case 'staticPageContent':
            props = {
                ...component,
            };
            Component = StaticPageContent;
            break;
        case 'searchResult':
            props = {
                ...component,
            };
            Component = SearchResult;
            break;
        case 'twoColumnsContentBlock':
            props = {
                ...component,
            };
            if (props.contentBlockStyle === 'List' || props.contentBlockStyle === '') {
                Component = TwoColumnsContentBlock;
            } else if (props.contentBlockStyle === 'Carousel') {
                Component = TwoColumnsContentBlockCarousel;
            } else {
                Component = TwoColumnsContentBlock;
            }
            break;
        case 'twoColumnsCustomContentBlock':
            props = {
                ...component,
            };
            Component = TwoColumnsCustomContentBlock;
            break;
        case 'fullColumnTextBlock':
            props = {
                ...component,
            };
            Component = FullColumnTextBlock;
            break;
        case 'disclaimerItem':
            props = {
                ...component,
            };
            Component = Disclaimer;
            break;
        case 'inTheNewsListing':
            props = {
                ...component,
            };
            Component = InTheNewsListing;
            break;
        case 'accordionList':
            props = {
                ...component,
            };
            Component = AccordionList;
            break;
        case 'expandableSection':
            props = {
                ...component,
            };
            Component = ExpandableSection;
            break;
        case 'accordionIconList':
            props = {
                ...component,
            };
            Component = AccordionIconList;
            break;
        case 'accordionIconItem':
            props = {
                ...component,
            };
            Component = AccordionIconItem;
            break;

        // New 'items' for more flexible usage
        case 'textItem':
            props = {
                ...component,
            };
            Component = TextItem;
            break;
        case 'statsDataItem':
            props = {
                ...component,
            };
            Component = StatsItem;
            break;
        case 'imageItem':
            props = {
                ...component,
            };
            Component = ImageItem;
            break;
        case 'chartItem':
            props = {
                ...component,
            };
            Component = Chart;
            break;

        default:
    }

    if (Component) {
        return <Component key={key} {...props} isMobile={isMobile} />;
    }
    return null;
};
