import React from 'react';
import PropTypes from 'prop-types';
import style from './TwoColumnsCustomContentBlock.module.scss';
import { render } from '../render';
import { SmartLink } from '../SmartLink';

const TwoColumnsCustomContentBlock = props => {
    const { content1, content2, backgroundColour, cta } = props;

    return (
        <>
            <div className={[style.el, style[`bg-${backgroundColour}`], style[`cta-${cta.text.length ? 'on' : 'off'}`]].join(' ')}>
                <div className={style.content1}>
                    {content1.map(item => (
                        render(item)
                    ))}
                </div>
                <div className={style.content2}>
                    {content2.map(item => (
                        render(item)
                    ))}
                </div>
            </div>
            { cta.text.length ? <div className={style.cta}>
                <div className={style.ctaLink}>
                    <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                        {cta.text}
                    </SmartLink>
                </div>
            </div> : <></> }
        </>
    );
};

TwoColumnsCustomContentBlock.propTypes = {
    content1: PropTypes.object,
    content2: PropTypes.object,
    cta: PropTypes.object,
    backgroundColour: PropTypes.string,
};
export default TwoColumnsCustomContentBlock;

TwoColumnsCustomContentBlock.defaultProps = {};
