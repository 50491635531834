import React from 'react';
import PropTypes from 'prop-types';

import style from './TextItem.module.scss';

const TextItem = props => {
    const { title, body } = props;

    return (
        <div className={style.el}>
            <div className={[style.container].join(' ')}>
                {title && <h1 className={style.headline}>{title}</h1>}
                {body && <p className={style.desc} dangerouslySetInnerHTML={{ __html: body }}></p>}
            </div>
        </div>
    );
};

TextItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    body: PropTypes.string,
};

export default TextItem;
