import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';
import { useRouter } from 'next/router';

import { scrollToTargetOffset } from '../../utils/common';
import CaseStudyCard from '../Cards/caseStudyCard';
import Pagination from '../Pagination';

import { getCaseStudy, getCaseStudyLoading } from '../../redux/caseStudy/caseStudy.selector';
import { fetchCaseStudy } from '../../redux/caseStudy/caseStudy.action';

import style from './CaseStudyListing.module.scss';

const CaseStudyListing = ({ caseStudies, copies }) => {
    const elRef = useRef(null);
    const [sort] = useState('latest');
    const state = useSelector(state => state);
    // const router = useRouter();

    const caseStudyFromApi = getCaseStudy(state);
    const caseStudyLoading = getCaseStudyLoading(state);

    const dispatch = useDispatch();

    const [caseStudyCache, setCaseStudyCache] = useState(caseStudies);

    const handlePageChange = page => {
        dispatch(fetchCaseStudy(page, sort));
    };

    const router = useRouter();

    useEffect(() => {
        // isFirstRun.current = true;
        // isFirstSort.current = true;
        return () => {
            setCaseStudyCache(caseStudies);
        };
    }, [router.pathname]);

    const startLoading = useRef(false);

    useEffect(() => {
        if (caseStudyLoading) {
            startLoading.current = true;
        }
        if (
            startLoading.current &&
            !caseStudyLoading &&
            caseStudyFromApi?.data !== undefined &&
            JSON.stringify(caseStudyFromApi) !== JSON.stringify(caseStudyCache)
        ) {
            setCaseStudyCache(caseStudyFromApi);
            scrollToTargetOffset(elRef.current, isDesktop ? 110 : 80);
            startLoading.current = false;
        }
    }, [caseStudyLoading]);

    const isDesktop = useMediaQuery('only screen and (min-width: 768px)');
    return (
        <div className={style.el} ref={elRef}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={[style.numberTeamMembers].join(' ')}>
                    <div>
                        {copies?.caseCountLabel && (
                            <>
                                <span className={style.number}>{caseStudyCache.totalItems}</span>{' '}
                                {copies?.caseCountLabel}
                            </>
                        )}
                    </div>
                </div>
                <div className={style.newsWrapper}>
                    {caseStudyCache.data.length > 0 ? (
                        caseStudyCache.data.map((item, i) => (
                            <div className={style.caseStudyCard} key={`caseStudy${i}`}>
                                <CaseStudyCard {...item} />
                            </div>
                        ))
                    ) : (
                        <div className={style.noResults}>{copies?.noResultMessage}</div>
                    )}
                </div>
                {caseStudyCache.totalPages > 0 && (
                    <Pagination
                        total={caseStudyCache.totalPages + 1}
                        active={caseStudyCache.currentPage}
                        changePage={handlePageChange}
                    />
                )}
            </div>
        </div>
    );
};

CaseStudyListing.propTypes = {
    caseStudies: PropTypes.object,
    copies: PropTypes.object,
};

export default CaseStudyListing;
