import React, { useEffect, useState } from 'react';
import style from './HomeTreasers.module.scss';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { useMediaQuery } from '@react-hook/media-query';

const HomeTreasers = props => {
    const { items: desktopItems, mobileItems } = props;

    const isMobile = useMediaQuery('only screen and (max-width: 991px)');

    const [items, setItems] = useState(desktopItems);

    useEffect(() => {
        if (isMobile && mobileItems) {
            setItems(mobileItems);
        }
    }, [isMobile, mobileItems]);

    return (
        <div className={[style.container, 'container'].join(' ')}>
            <div className={style.treaserWrapper}>
                {items.length &&
                    items.map(({ id, image, title, mobileImage, description, cta }) => (
                        <div key={id} className={style.treasersCardContainer}>
                            <a href={cta.link.url} target='_self'>
                                <div className={style.treasersCard}>
                                    <div className={style.treaserImageBox}>
                                        <img key={id} src={isMobile ? mobileImage.url : image.url} alt={image.alt} />
                                    </div>
                                    <div className={style.treaserCopy}>
                                        <div className={style.treaserTitle}>
                                            <h3>{title}</h3>
                                        </div>
                                        <div className={style.treaserDescription}>
                                            <p>{parse(description)}</p>
                                        </div>
                                        <div className={style.treaserCta}>
                                            {cta.text}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
            </div>
        </div>
    );
};

HomeTreasers.propTypes = {
    type: PropTypes.string,
    items: PropTypes.array,
    mobileItems: PropTypes.array,
};

export default HomeTreasers;
