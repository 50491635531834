import React from 'react';
import PropTypes from 'prop-types';
import style from './AccordionIconItem.module.scss';

const AccordionIconItem = ({ content, title, icon, index, selectedChild, updateSelectedChild }) => {
    return (
        <div className={[style.el, !content ? style.disabled : ''].join(' ')} data-open={selectedChild === index || false} onClick={updateSelectedChild.bind(this, index)} data-index={index}>
            <div className={style.icon}>
                <img src={icon.url} alt={icon.alt} />
            </div>
            { content && (
                <div className={style.content}>
                    <div className={style.contentPosition}>
                        <div className={style.title}>{title}</div>
                        <div className={style.description} dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>
                </div>
            )}
        </div>
    );
};

AccordionIconItem.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    icon: PropTypes.object,
    index: PropTypes.number,
    selectedChild: PropTypes.number,
    updateSelectedChild: PropTypes.func
};
export default AccordionIconItem;

AccordionIconItem.defaultProps = {
    title: 'Heading',
    content: 'Content',
};
