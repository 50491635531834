import React from 'react';
import PropTypes from 'prop-types';

import Card from './Card';

import style from './JumpOffCards.module.scss';

const JumpOffCards = props => {
    const { items } = props;

    return (
        <div className={style.el}>
            <div className={style.container}>
                <div className={style.wrapper}>
                    {items.length && items.map((item, i) => <Card {...item} key={`card${i}`} />)}
                </div>
            </div>
        </div>
    );
};

JumpOffCards.propTypes = {
    type: PropTypes.string,
    items: PropTypes.array,
};

JumpOffCards.defaultProps = {
    items: [
        {
            id: '',
            title: 'Meet Our People',
            description:
                'Explore the diverse backgrounds and perspectives of our team across 15 offices around the world.',
            image: {
                url: 'https://via.placeholder.com/877x470.jpg',
                alt: 'image-1',
            },
            cta: {
                text: 'Read more',
                link: {
                    target: '',
                    url: '/people/meet-out-people',
                },
            },
        },
        {
            id: '',
            title: 'Culture and Values',
            description: 'Partnership. Integrity. Creativity. The guiding principles behind everything we do.',
            image: {
                url: 'https://via.placeholder.com/877x470.jpg',
                alt: 'image-1',
            },
            cta: {
                text: 'Read more',
                link: {
                    target: '',
                    url: '/people/culture-and-values',
                },
            },
        },
        {
            id: '',
            title: 'Life at Permira',
            description: 'Find out more about our dynamic and rewarding working environment.',
            image: {
                url: 'https://via.placeholder.com/877x470.jpg',
                alt: 'image-1',
            },
            cta: {
                text: 'Read more',
                link: {
                    target: '',
                    url: '/people/life-at-permira',
                },
            },
        },
    ],
};

export default JumpOffCards;
