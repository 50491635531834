import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';
import { useRouter } from 'next/router';
import debounce from 'lodash.debounce';
import { animateScroll as scroll } from 'react-scroll';

import { setBodyNoScroll } from '../../utils/common';
import FilterSelect from '../FilterSelect';
import NewsCard from './NewsCard';
import CustomSelect from '../CustomSelect';
import { prepareGlobalFilterClasses } from '../../styles/globalFilterClasses';

import { getFilters, getNews, getNewsLoading } from '../../redux/news/news.selector'; // getNews
import { addFilter, removeFilter, clearFilters, fetchNews, setFilters } from '../../redux/news/news.action'; // addFilter, clearFilters, fetchNews
import InfiniteScroll from 'react-infinite-scroll-component';
import LottieAnimation from '../LottieAnimation';
import { loadingLottieJSON } from '../../utils/loadingLottieJSON';

import style from './NewsListing.module.scss';
import {
    convertFiltersToObject,
    getFiltersFromQueryString,
    removeQuery,
    setQueryStringValue,
    getQueryStringValue,
    removeQueryStringValue,
} from '../../utils/queryString';
import CarouselStandard from '../CarouselStandard';

const NewsListing = ({ filters, news, copies }) => {
    const seriesFilterString = 'series';
    const seriesItems = filters.find(filter => filter.type === seriesFilterString)?.data || [];
    const filtersRef = useRef(null);
    const elRef = useRef(null);
    const [filterOpen, setFilterOpen] = useState(false);
    const [sort, setSort] = useState('latest');
    const [page, setPage] = useState(0);
    // const [currentPage, setCurrentPage] = useState(0);
    const state = useSelector(state => state);
    const selectedFilters = getFilters(state);
    const router = useRouter();

    const searchFilter = selectedFilters.find(filter => filter.single) || { value: '' };
    const searchField = useRef(null);
    const setSearchFieldValue = value => {
        searchField.current.value = value;
    };
    useEffect(() => {
        setSearchFieldValue(searchFilter.value);
    }, []);
    const handleSearchChanged = e => {
        const type = e.target.name;
        const value = e.target.value;
        const label = value;
        const obj = { type: type, value: value, label: label, single: true };
        if (value === '') {
            dispatch(clearFilters(type));
        } else {
            dispatch(addFilter(obj));
        }
    };
    const debouncedSearchChanged = useMemo(() => debounce(event => handleSearchChanged(event), 300), []);
    const newsFromApi = getNews(state);
    const newsLoading = getNewsLoading(state);

    const dispatch = useDispatch();
    const handleFilterSelect = e => {
        const type = e.currentTarget.name;
        const value = e.currentTarget.value;
        const label = e.currentTarget.getAttribute('data-label');
        const obj = { type: type, value: value, label: label };
        if (value === 'all') {
            dispatch(clearFilters(type));
        } else {
            if (selectedFilters.length < 8) dispatch(addFilter(obj));
            else dispatch(removeFilter(obj));
        }
    };

    const [newsCache, setNewsCache] = useState(news);
    const [filterCache, setFilterCache] = useState(selectedFilters);

    const sortSelect = [
        {
            label: copies.sortLatestLabel,
            value: 'latest',
        },
        {
            label: copies.sortAZLabel,
            value: 'a_z',
        },
        {
            label: copies.sortZALabel,
            value: 'z_a',
        },
    ];

    const addFilterSeries = series => {
        const obj = { type: 'series', value: series.value, label: series.featureTitle };
        dispatch(addFilter(obj));
        scroll.scrollToTop({
            duration: 0,
        });
    };

    const removeThisFilter = e => {
        const type = e.currentTarget.getAttribute('data-type');
        const value = e.currentTarget.getAttribute('data-value');
        const label = e.currentTarget.getAttribute('data-label');
        const obj = { type: type, value: value, label: label };
        if (type === 'keywords') setSearchFieldValue('');
        dispatch(addFilter(obj));
    };

    const clearFiltersClick = () => {
        dispatch(clearFilters(''));
    };

    const fetchData = () => {
        setPage(page + 1);
    };

    const filterClick = () => {
        setFilterOpen(true);
    };

    const convertFiltersToString = () => {
        return JSON.stringify(convertFiltersToObject(selectedFilters));
    };

    useEffect(() => {
        if (JSON.stringify(filterCache) !== JSON.stringify(selectedFilters)) {
            setFilterCache(selectedFilters);
        }
    }, [selectedFilters]);

    const isFirstRun = useRef(true);

    useEffect(() => {
        isFirstRun.current = true;
        isFirstSort.current = true;
        return () => {
            setNewsCache(news);
            dispatch(clearFilters(''));
        };
    }, [router.pathname]);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            const filtersFromQueryString = getFiltersFromQueryString('filters');
            dispatch(setFilters(filtersFromQueryString));
            return;
        }
        setQueryStringValue('filters', convertFiltersToObject(selectedFilters || []), '', router);
        dispatch(fetchNews(removeQuery(router.asPath), 0, sort, convertFiltersToString()));
    }, [filterCache]);

    useEffect(() => {
        const handleRouteChange = url => {
            const queryString = url.split('?').pop();
            const filters = getFiltersFromQueryString('filters', queryString);
            const sort = getQueryStringValue('sort', queryString);

            if (filters && filters.length) {
                dispatch(setFilters(filters));
            } else {
                dispatch(clearFilters(''));
            }
            if (sort) {
                setSort(sort);
            } else {
                setSort('latest');
            }
            setPage(0);
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    const startLoading = useRef(false);

    useEffect(() => {
        if (newsLoading) {
            startLoading.current = true;
        }
        if (
            startLoading.current &&
            !newsLoading &&
            newsFromApi?.data !== undefined &&
            JSON.stringify(newsFromApi) !== JSON.stringify(newsCache)
        ) {
            setNewsCache(newsFromApi);
            startLoading.current = false;
        }
    }, [newsLoading]);

    const isFirstLoad = useRef(true);

    useEffect(() => {
        if (isFirstLoad.current) {
            filters.map(type => {
                if (router.query[type.type] !== undefined) {
                    const queryFilters = router.query[type.type].split(',');
                    queryFilters.map(queryFilter => {
                        const hasFilter = type.data.filter(f => f.value === queryFilter);
                        if (hasFilter.length > 0) {
                            const obj = { type: type.type, value: hasFilter[0].value, label: hasFilter[0].label };
                            dispatch(addFilter(obj));
                        }
                        return false;
                    });
                }
                return false;
            });
            isFirstLoad.current = false;
            return;
        }
        return () => {
            // clearAllBodyScrollLocks();
        };
    });

    useEffect(() => {
        const sortValue = getQueryStringValue('sort', '', router);
        if (sortValue && sort !== sortValue) {
            setSort(sortValue);
        }
    }, []);

    useEffect(() => {
        if (filterOpen) {
            setBodyNoScroll(true);
        } else {
            setBodyNoScroll(false);
        }
        return () => {
            setBodyNoScroll(false);
        };
    }, [filterOpen]);

    const isDesktop = useMediaQuery('only screen and (min-width: 768px)');

    useEffect(() => {
        if (isDesktop) {
            setFilterOpen(false);
        }
    }, [isDesktop]);

    const deHyphenate = name => name.replace(/-/g, ' ');

    const RenderSelectedFilters = () => (
        <>
            {selectedFilters.map((filter, i) => (
                <button
                    key={i}
                    type='button'
                    data-type={filter.type}
                    data-label={filter.label}
                    data-value={filter.value}
                    className={style.filterButton}
                    onClick={removeThisFilter}
                >
                    {deHyphenate(filter.label)}
                    <span className={style.cross} />
                </button>
            ))}
        </>
    );

    const handleSortChange = value => {
        const val = typeof value === 'string' ? value : value.target.value;
        if (val !== sort) {
            setSort(val);
        }
    };

    const isFirstSort = useRef(true);

    useEffect(() => {
        if (isFirstSort.current) {
            isFirstSort.current = false;
            return;
        }
        dispatch(fetchNews(removeQuery(router.asPath), page, sort, convertFiltersToString()));
        if (sort === 'latest') {
            removeQueryStringValue('sort', '', router);
        } else {
            setQueryStringValue('sort', sort, '', router);
        }
    }, [sort]);

    useEffect(() => {
        dispatch(fetchNews(removeQuery(router.asPath), page, sort, convertFiltersToString(selectedFilters), true));
    }, [page]);

    const [categorise, setCategorise] = useState(false);
    const [showTitleBlock, setShowTitleBlock] = useState(false);
    const [titleBlockDetails, setTitleBlockDetails] = useState({});
    useEffect(() => {
        const filtersContainSeries = filters.find(filter => filter.type === seriesFilterString);
        const selectedFiltersContainSeries = selectedFilters.find(filter => filter.type === seriesFilterString);
        const selectedFiltersContainFilterOrSearch = selectedFilters.find(filter => filter.type !== seriesFilterString);

        if (selectedFiltersContainSeries) {
            const selectedSeriesData = seriesItems.find(series => series.value === selectedFiltersContainSeries.value);
            setTitleBlockDetails(selectedSeriesData);
            setShowTitleBlock(true);
        } else setShowTitleBlock(false);

        if (!filtersContainSeries || selectedFiltersContainFilterOrSearch || selectedFiltersContainSeries) {
            setCategorise(false);
        } else {
            setPage(0);
            setCategorise(true);
        }
    }, [filterCache]);

    /**
     * Builds content for the page based on the filter / seach / series states
     * */
    const buildContent = function (shouldCategorise) {
        if (shouldCategorise) {
            return seriesItems.map((series, index) => {
                const teaserItems = series.seriesData[0]?.seriesTeaserItems;
                if (teaserItems?.length) {
                    return (
                        <SeriesCategory
                            key={index}
                            series={series}
                            teaserItems={teaserItems}
                            selectedFilters={selectedFilters}
                            addFilterSeries={addFilterSeries}
                        />
                    );
                } else {
                    return <></>;
                }
            });

            // No categorisation
        } else {
            return (
                <InfiniteScroll
                    dataLength={newsCache.data.length}
                    next={newsCache.currentPage < newsCache.totalPages && fetchData}
                    hasMore={newsCache.currentPage < newsCache.totalPages}
                    loader={<div className={style.loading}><LottieAnimation json={loadingLottieJSON} loop={true} playImmediately={true} /></div>}
                    endMessage={<div></div>}
                >
                    {newsCache.data.length > 0 ? (
                        newsCache.data.map((item, i) => (
                            <NewsCard key={`people${i}`} {...item} selectedFilters={selectedFilters} />
                        ))
                    ) : (
                        <div className={style.noResults}>{copies?.noResultMessage}</div>
                    )}
                </InfiniteScroll>
            );
        }
    };

    return (
        <div className={style.el} ref={elRef}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.filters}>
                    <div className={style.filtersMobile}>
                        {selectedFilters?.length > 0 ? (
                            <button type='button' className={style.clearButton} onClick={clearFiltersClick}>
                                <span className='icon-close' />
                                {copies?.clear}
                            </button>
                        ) : (
                            <div />
                        )}
                        <button type='button' className={style.filterButton} onClick={filterClick}>
                            <span className='icon-filter' />
                            {copies?.filter}
                        </button>
                    </div>
                    <div
                        className={
                            [style.filtersWrapper, filterOpen ? style.open : ''].join(' ') +
                            prepareGlobalFilterClasses(filters.length, filters.length, true)
                        }
                        ref={filtersRef}
                    >
                        <div className={style.filterHeadMobile}>
                            <button className={style.backBtn} onClick={() => setFilterOpen(false)}>
                                {/* <span className='icon-arrow-right' /> */}
                                <span className='sr-only'>back</span>
                            </button>
                            <button className={style.closeBtn} onClick={() => setFilterOpen(false)}>
                                <span className='icon-close' />
                                <span className='sr-only'>close</span>
                            </button>
                        </div>
                        {selectedFilters?.length > 0 && (
                            <div className={[style.selectedFilters, 'mobile'].join(' ')}>
                                <RenderSelectedFilters />
                            </div>
                        )}
                        <div className={[style.filterSearchWrap, 'mobile'].join(' ')}>
                            <input
                                ref={searchField}
                                onKeyUp={debouncedSearchChanged}
                                className={style.searchBox}
                                type='text'
                                name='keywords'
                                placeholder={copies?.keywordsSearch}
                            />
                        </div>
                        {filters?.map(item => (
                            <div className={style.filterSelectWrap} key={item.type}>
                                <FilterSelect
                                    {...item}
                                    handleSelect={handleFilterSelect}
                                    selectedFilters={Array.from(selectedFilters).filter(f => f.type === item.type)}
                                />
                            </div>
                        ))}
                        <div className={style.filterBottomMobile}>
                            <button className={style.applyBtn} onClick={() => setFilterOpen(false)}>
                                {copies?.apply}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={style.filtersSort + prepareGlobalFilterClasses(filters.length, 2)}>
                    <div className={[style.filterSelectWrap, 'desktop'].join(' ') + ' ' + style.filterSearch}>
                        <input
                            ref={searchField}
                            onKeyUp={debouncedSearchChanged}
                            className={style.searchBox}
                            type='text'
                            name='keywords'
                            placeholder={copies?.keywordsSearch}
                        />
                    </div>
                    {!categorise && (
                        <div role='status' className={[style.numberTeamMembersMobile, 'mobile'].join(' ')}>
                            {copies?.countLabel && (
                                <>
                                    <span className={style.number}>{newsCache.totalItems}</span> {copies?.countLabel}
                                </>
                            )}
                        </div>
                    )}
                    <div className={style.sortSelect}>
                        <CustomSelect
                            active={sort}
                            data={sortSelect}
                            defaultTitle={copies?.sortBy}
                            colourVariant={'orange'}
                            handleSelect={handleSortChange}
                        />
                    </div>
                </div>
                <div className={style.filtersSelected}>
                    <div className={[style.selectedFilters, 'desktop'].join(' ')}>
                        {selectedFilters.length > 0 && <RenderSelectedFilters />}
                    </div>

                    <div className={style.filterResults}>
                        <div className={[style.numberTeamMembers, 'desktop'].join(' ')}>
                            {!categorise && (
                                <div role='status'>
                                    {copies?.countLabel && (
                                        <>
                                            <span className={style.number}>{newsCache.totalItems}</span>{' '}
                                            {copies?.countLabel}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        {selectedFilters.length > 0 && (
                            <button
                                type='button'
                                className={[style.clearButton, 'desktop'].join(' ')}
                                onClick={clearFiltersClick}
                            >
                                <span className='icon-close' />
                                {copies?.clearFilters}
                            </button>
                        )}
                    </div>
                </div>

                {showTitleBlock && (
                    <div className={style.seriesTitleBlock}>
                        <div className={style.seriesTitleColumn1}>
                            <h2 className={style.seriesTitle}>{titleBlockDetails.featureTitle}</h2>
                            <div
                                className={style.seriesDesc}
                                dangerouslySetInnerHTML={{ __html: titleBlockDetails.featureContent }}
                            ></div>
                        </div>
                        <div
                            className={style.seriesTitleColumn2}
                            style={{ backgroundImage: `url(${titleBlockDetails.featureImage?.url})` }}
                        ></div>
                    </div>
                )}
                <div className={style.newsWrapper}>{buildContent(categorise)}</div>
            </div>
        </div>
    );
};

NewsListing.propTypes = {
    filters: PropTypes.array,
    news: PropTypes.object,
    copies: PropTypes.object,
};

export default NewsListing;

/**
 * Subcomponent: Series Category
 * When a series is show as a category, with slider
 * */
function SeriesCategory ({ series, teaserItems, selectedFilters, addFilterSeries }) {
    const sliderParams = {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        infinite: true,
        speed: 700,
        draggable: false,
        swipe: false,
        variableWidth: false,
    };

    const sliderBreakpoints = [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ];

    const ctaCallback = {
        onClick: addFilterSeries.bind(this, series),
        text: 'See All Series Insights'
    }

    return (
        <div className={style.seriesCategory}>
            {teaserItems?.length > 1 && (
                <CarouselStandard className={style.carouselOverrides} title={series.featureTitle} secondaryText={series.featureContent} ctaCallback={ctaCallback} sliderParams={sliderParams} sliderBreakpoints={sliderBreakpoints}>
                    {teaserItems?.map((item, itemIndex) => {
                        return (
                            <NewsCard
                                key={`people${itemIndex}`}
                                stretchWidthDesktop={true}
                                {...item}
                                selectedFilters={selectedFilters}
                            />
                        );
                    })}
                </CarouselStandard>
            )}
            {teaserItems?.length === 1 && (
                <div className={style.singleTeaserContainer}>
                    <NewsCard
                        key={`people${0}`}
                        stretchWidthDesktop={true}
                        {...teaserItems[0]}
                        selectedFilters={selectedFilters}
                    />
                </div>
            )}
        </div>
    );
}

SeriesCategory.propTypes = {
    series: PropTypes.object,
    teaserItems: PropTypes.array,
    selectedFilters: PropTypes.array,
    addFilterSeries: PropTypes.func,
};
