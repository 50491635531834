import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import style from './Lifecycle.module.scss';

const Lifecycle = props => {
    const { title, description, items } = props;
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        function setWH () {
            const wh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            document.documentElement.style.setProperty('--vh', `${wh}px`);
            const vw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const containerWidth = vw > 1440 ? 1440 : vw - 60;
            const itemsWidth = containerWidth - 60 * (items.length - 1);
            let itemWidth = itemsWidth / items.length;
            if (itemWidth < 300) {
                itemWidth = 300;
            }
            document.documentElement.style.setProperty('--investment-item-height', `${itemWidth}px`);
            document.documentElement.style.setProperty('--investment-item-text-width', `${itemsWidth / 2 - 60}px`);
        }
        window.addEventListener('resize', setWH);
        setWH();
        return () => {
            window.removeEventListener('resize', setWH);
        };
    });

    const handleItemClick = i => {
        if (i === selected) {
            setSelected(null);
        } else {
            setSelected(i);
        }
    };

    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.content}>
                    <h2 className={style.title}>{title}</h2>
                    <div className={style.description} dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                <div
                    className={[
                        style.list,
                        selected !== null ? style.hasSelected : '',
                        items.length === 3 ? style.threeItems : '',
                    ].join(' ')}
                >
                    {items &&
                        items.map((item, i) => {
                            return (
                                <div
                                    className={[
                                        style.item,
                                        selected === i ? style.selected : '',
                                        item.disclaimerText ? style.hasDisclaimer : '',
                                    ].join(' ')}
                                    key={i}
                                    onClick={() => handleItemClick(i)}
                                >
                                    <div className={style.itemInner}>
                                        <div className={['h1', style.number].join(' ')}>{item.number}</div>
                                        <div className={['h3', style.text].join(' ')}>{item.title}</div>
                                        <button className={style.button}>{item.cta.text}</button>
                                    </div>
                                    <div
                                        className={style.content}
                                        dangerouslySetInnerHTML={{ __html: item.description }}
                                    />
                                    <div
                                        className={style.disclaimer}
                                        dangerouslySetInnerHTML={{ __html: item.disclaimerText }}
                                    ></div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

Lifecycle.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.array,
};

export default Lifecycle;
