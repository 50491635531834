import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import style from './FeatureArticle.module.scss';
import { SmartLink } from '../SmartLink';
import { removeQuery } from '../../utils/queryString';

const FeatureArticle = props => {
    const { title, description, item, popup } = props;
    const router = useRouter();
    const pagePath = item?.cta?.link.url.replace(/\/$/, '');
    const link = `${removeQuery(router.asPath)}?pageId=${pagePath}`;

    return (
        <>
            <div className={style.el}>
                <div className={[style.container, 'container'].join(' ')}>
                    <h1 className={style.headline}>{title}</h1>
                    <p className={style.desc}>{description}</p>
                </div>
                {item.id && (
                    <div className={style.content}>
                        <div className={style.wrapper}>
                            <div
                                className={style.banner}
                                style={{
                                    backgroundImage: `url(${item.image?.url})`,
                                    backgroundSize: 'cover',
                                }}
                            />
                        </div>
                        <div className={style.wrapper}>
                            <div className={style.header}>
                                <div className={style.type}>{item.type}</div>
                                <div className={style.date}>{item.publishingDate}</div>
                            </div>
                            <h2 className={style.title}>{item.title}</h2>
                            {item.cta && (
                                <div className={style.button}>
                                    {popup ? (
                                        <SmartLink href={link} hasArrow className='arrow-link' as={item.cta.link.url}>
                                            {item.cta.text}
                                        </SmartLink>
                                    ) : (
                                        <SmartLink href={item.cta.link.url} hasArrow className='arrow-link'>
                                            {item.cta.text}
                                        </SmartLink>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

FeatureArticle.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    item: PropTypes.object,
    popup: PropTypes.bool,
};
export default FeatureArticle;

FeatureArticle.defaultProps = {};
