import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from '@react-hook/window-size';
import ChartJS from 'chart.js/auto';
import style from './Chart.module.scss';
import { backgroundColorDefault, backgroundColorTransparent, configDefaultDoughnut, configDefaultBar, configDefaultPie, configDefaultLine, configDefaultPolarArea, modifyConfig } from './chart_config';

const Chart = props => {
    const { title, hasLegend, dataset, chartType } = props;
    const chartTypeLowerKey = chartType.toLowerCase();
    const customLegend = chartTypeLowerKey === 'bar' || chartTypeLowerKey === 'line';

    let config;
    let colourSet;

    switch (chartTypeLowerKey) {
        case 'doughnut':
        case 'donut':
            colourSet = backgroundColorDefault;
            config = configDefaultDoughnut;
            break;
        case 'pie':
            colourSet = backgroundColorDefault;
            config = configDefaultPie;
            break;
        case 'line':
            colourSet = backgroundColorDefault;
            config = configDefaultLine;
            break;
        case 'polar area':
        case 'polar angle':
            colourSet = backgroundColorTransparent;
            config = configDefaultPolarArea;
            break;
        case 'bar':
        default:
            colourSet = backgroundColorDefault;
            config = configDefaultBar;
            break;
    }

    const canvasRef = useRef(null);
    const chartRef = useRef(null);

    if (!title || !title.length) {
        config = modifyConfig(config, 'titleoff');
    }

    useEffect(() => {
        const dataValues = {
            labels: dataset.map((datum) => {
                if (datum.dataKey.length > 40 && (customLegend || chartTypeLowerKey === 'line')) {
                    const label = datum.dataKey.split(' ');

                    const midpoint = Math.floor(label.length / 2);

                    const firstPart = label.slice(0, midpoint).join(' ');
                    const secondPart = label.slice(midpoint).join(' ');

                    return [firstPart, secondPart];
                }
                return datum.dataKey;
            }),
            datasets: customLegend ? dataset.map((datum, index) => {
                return {
                    label: datum.dataKey,
                    data: [...new Array(index), datum.dataValue],
                    skipNull: true,
                    backgroundColor: colourSet?.backgroundColor[index]
                }
            }) : [
                {
                    label: title || '',
                    data: dataset.map((datum) => datum.dataValue),
                    ...colourSet,
                    hoverOffset: 4,
                },
            ],

        };
        const { options = {} } = config;
        const { plugins = {} } = options;

        const configValues = {
            ...config,
            options: {
                ...options,
                plugins: {
                    ...plugins,
                    legend: {
                        display: hasLegend,
                        position: 'bottom',
                        labels: {
                            font: {
                                size: 18,
                                family: 'Graphik, Helvetica, Arial, sans-serif'
                            }
                        }
                    }
                }
            },
            data: dataValues,
        };

        if (chartRef.current) chartRef.current.destroy();
        chartRef.current = new ChartJS(canvasRef.current, configValues);

        return () => {
            chartRef.current && chartRef.current.destroy();
        }
    }, []);

    const [wWidth, wHeight] = useWindowSize();
    useEffect(() => {
        chartRef.current.resize();
    }, [wWidth, wHeight]);

    // JSX
    return (
        <div className={style.el}>
            {title && <div className={style.title}>{title}</div>}
            <div className={style.responsiveContainer}><canvas ref={canvasRef}></canvas></div>
        </div>
    );
};

Chart.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    chartType: PropTypes.string,
    hasLegend: PropTypes.bool,
    dataset: PropTypes.array
};

export default Chart;
