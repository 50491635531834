import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import style from './PageSelect.module.scss';

const PageSelect = ({ active, total, handleSelect }) => {
    const compRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const data = [];
    for (let i = 0; i < total; i++) {
        data.push({
            label: i + 1,
            value: i,
        });
    }

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        function outsideClick (e) {
            if (!compRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        }
        window.addEventListener('click', outsideClick);
        return () => {
            window.removeEventListener('click', outsideClick);
        };
    });

    return (
        <div className={style.el} ref={compRef}>
            <div className={style.button}>
                <button type='button' onClick={toggleOpen}>
                    <span className={style.title}>{active + 1}</span>
                    <span className={[style.icon, isOpen ? style.open : '', 'icon-arrow-up'].join(' ')} />
                </button>
                <select name='page' onChange={handleSelect} defaultValue={active}>
                    {data.map((option, i) => {
                        return i === active ? (
                            <option key={option.value} value={option.value} selected>
                                {option.label}
                            </option>
                        ) : (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div
                className={[style.options, isOpen ? style.active : style.closed].join(' ')}
                style={{ '--var-mh': `${38 * data.length + 10}px` }}
            >
                <div className={style.optionsWrap}>
                    {data.map(option => {
                        return (
                            <div
                                key={option.value}
                                className={[style.optionItem, active === option.value ? style.active : ''].join(' ')}
                            >
                                <button
                                    type='button'
                                    onClick={() => {
                                        handleSelect(option.value);
                                        setIsOpen(false);
                                    }}
                                >
                                    <span>{option.label}</span>
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

PageSelect.propTypes = {
    active: PropTypes.number,
    total: PropTypes.number,
    handleSelect: PropTypes.func,
};

export default PageSelect;
