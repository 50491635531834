import React from 'react';
import PropTypes from 'prop-types';
import style from './FourColsInfo.module.scss';

const FourColsInfo = props => {
    const { items, title } = props;
    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.content}>
                    <h2 className={style.title}>{title}</h2>
                    <div className={style.items}>
                        {items &&
                            items.map((item, i) => {
                                return (
                                    <div className={style.item} key={i}>
                                        <img className={style.image} src={item.icon.url} alt={item.icon.alt} />
                                        <div
                                            className={style.desc}
                                            dangerouslySetInnerHTML={{
                                                __html: item.text,
                                            }}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

FourColsInfo.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
};
export default FourColsInfo;

FourColsInfo.defaultProps = {};
