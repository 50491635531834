import React from 'react';
import PropTypes from 'prop-types';
import style from './TwoColumnsContentBlock.module.scss';
import TwoColumnsHeroSectionLeft from '../TwoColumnsHeroSectionLeft';
import TwoColumnsHeroSectionRight from '../TwoColumnsHeroSectionRight';

const TwoColumnsContentBlock = props => {
    const { contents, isMobile } = props;

    return (
        <div className={[style.el, 'two-col-list'].join(' ')}>
            <div className={style.content}>
                <div className={style.items}>
                    {contents &&
                        contents.map((item, id) => {
                            const backgroundColor =
                                item.backgroundColour === 'white'
                                    ? style.white
                                    : item.backgroundColour === 'grey'
                                    ? style.grey
                                    : '';
                            if (item.imageOrientation === 'Left') {
                                return (
                                    <div className={[style.image, backgroundColor].join(' ')} key={id}>
                                        <div className={[style.container, 'container'].join(' ')}>
                                            <TwoColumnsHeroSectionLeft
                                                title={item.title}
                                                description={item.description}
                                                image={item.image}
                                                video={item.video}
                                                coverImage={item.coverImage}
                                                stats={item.stats}
                                                cta={item.cta}
                                                isMobile={isMobile}
                                            />
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className={[style.image, backgroundColor].join(' ')} key={id}>
                                        <div className={[style.container, 'container'].join(' ')}>
                                            <TwoColumnsHeroSectionRight
                                                title={item.title}
                                                description={item.description}
                                                image={item.image}
                                                video={item.video}
                                                coverImage={item.coverImage}
                                                stats={item.stats}
                                                cta={item.cta}
                                                isMobile={isMobile}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                        })}
                </div>
            </div>
        </div>
    );
};

TwoColumnsContentBlock.propTypes = {
    title: PropTypes.any,
    description: PropTypes.string,
    contents: PropTypes.array,
    isMobile: PropTypes.bool,
};
export default TwoColumnsContentBlock;

TwoColumnsContentBlock.defaultProps = {};
