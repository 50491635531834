import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import style from './Banner.module.scss';

// const OverlayImage = () => (
//     <svg xmlns='http://www.w3.org/2000/svg' width='592' height='316' fill='none' viewBox='0 0 592 316'>
//         <path
//             fill='#E67C25'
//             d='M156.358 264.462c34.371-57.137-51.19-109.584-87.645-53.737L0 316h122.75c15.557-23.776 27.591-42.157 31.319-47.932.821-1.202 1.555-2.404 2.289-3.606zM473.946 76.75c33.021-57.401-52.276-108.353-88.261-52.77L196.717 316H319.79C387.24 211.751 462.734 94.955 471.657 80.561c.792-1.26 1.555-2.55 2.289-3.811zM591.999 11.316L394.637 316h122.28c23.452-36.176 49.047-75.665 75.082-115.799V11.316z'
//         />
//     </svg>
// );

const Banner = props => {
    const { title, cta, image, mobileImage, video, mobileVideo, isMobileDevice } = props;

    const isMobile = useMediaQuery('only screen and (max-width: 650px)');

    const useMobileImage = mobileImage?.url.length > 1 ? mobileImage.url : image?.url;

    const useMobileVideo = mobileVideo?.url.length > 1 ? mobileVideo.url : video?.url;

    const [videoSrc, setVideoSrc] = useState(isMobileDevice ? useMobileVideo : video?.url);

    useEffect(() => {
        setVideoSrc(isMobile ? useMobileVideo : video?.url);
    }, [isMobile]);

    return (
        <div className={style.el}>
            {cta ? (
                <>
                    <div className={style.section}>
                        {video.url.length ? (
                            <div className={style.videoContainer}>
                                {/* <div className={style.colorOverlay}></div> */}
                                {/* <iframe src="https://player.vimeo.com/video/76979871?background=1" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> */}
                                <video
                                    key={videoSrc}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    poster={isMobile ? useMobileImage : image.url}
                                >
                                    <source src={videoSrc} type='video/mp4' />
                                </video>
                            </div>
                        ) : (
                            <div
                                className={style.staticBanner}
                                style={{
                                    backgroundImage: `url(${isMobile ? mobileImage.url : image.url})`,
                                    backgroundSize: 'cover',
                                }}
                            >
                                {' '}
                            </div>
                        )}
                        {/* <div className={style.overlay}>
                            <OverlayImage />
                        </div> */}
                    </div>
                    {/* caption */}
                    <div className={style.heroBannerWrapper}>
                        <div className={[style.imageMeta, 'container'].join(' ')}>
                            <h1 className='h2'>{title}</h1>
                            <a href={cta.link.url} target='_self' className={style.heroLink}>
                                {cta.text}
                            </a>
                        </div>
                    </div>
                </>
            ) : (
                ' '
            )}
        </div>
    );
};

Banner.propTypes = {
    title: PropTypes.string,
    cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.object,
    }),
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    mobileImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    video: PropTypes.shape({
        url: PropTypes.string,
    }),
    mobileVideo: PropTypes.shape({
        url: PropTypes.string,
    }),
    isMobileDevice: PropTypes.bool,
};
export default Banner;
