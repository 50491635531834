import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';

import style from './ImageItem.module.scss';

const ImageItem = props => {
    const { image } = props;

    return (
        <div className={style.el}>
            {image && <Image src={image.url} alt={image.alt} layout="fill" />}
        </div>
    );
};

ImageItem.propTypes = {
    image: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
};

export default ImageItem;
