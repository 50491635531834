import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { render } from '../../components/render';

import style from './ExpandableSection.module.scss';

const ExpandableSection = props => {
    const { title, description, expandLinkText, collapseLinkText, contents } = props;

    const expandableRef = useRef(0);
    const [openState, setOpenState] = useState(false);
    const toggleOpenState = () => {
        if (openState) {
            expandableRef.current.style.maxHeight = '0px';
            setOpenState(false);
        } else {
            expandableRef.current.style.maxHeight = expandableRef.current.scrollHeight + 'px';
            setOpenState(true);
        }
    }

    useEffect(() => {
        let timeout = false;
        function setExpandableHeight () {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (openState) expandableRef.current.style.maxHeight = expandableRef.current.scrollHeight + 'px';
            }, 300);
        }
        window.addEventListener('resize', setExpandableHeight);
        return () => {
            window.removeEventListener('resize', setExpandableHeight);
        };
    });

    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.content}>
                    <h2 className={style.title}>{title}</h2>
                    <div className={style.description} dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                <div className={style.expandableFooter}>
                    <div className={style.expandableLaunch}>
                        <button onClick={toggleOpenState} className={openState ? '' : style.flipArrow}>
                            {openState ? collapseLinkText : expandLinkText } <span className={openState ? 'icon-arrow-up' : 'icon-arrow-up'} aria-hidden='true'></span>
                        </button>
                    </div>
                </div>
            </div>

            <div className={style.expandable + ' ' + (openState ? style.open : 'closed')} ref={expandableRef}>
                {contents && contents.map((content, i) => render(content))}
            </div>
        </div>
    );
};

ExpandableSection.propTypes = {
    title: PropTypes.string,
    expandLinkText: PropTypes.string,
    collapseLinkText: PropTypes.string,
    description: PropTypes.string,
    contents: PropTypes.array,
};

export default ExpandableSection;
