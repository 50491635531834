import hyperid from 'hyperid';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import AccordionList from '../AccordionList';
import { NewsDetailHeader } from '../NewsDetail';
import style from './Article.module.scss';

const StatsComponent = ({ title, stats }) => {
    return (
        <div className={style.fourColsInfo}>
            {title && <h2 className={style.titleStats}>{title}</h2>}
            <div className={style.stats}>
                {stats.map((stats, id) => (
                    <div className={style.statsInfo} key={id}>
                        <div className={['h1', style.statsTitle].join(' ')}>{stats.title}</div>
                        <div
                            className={style.statsDesc}
                            dangerouslySetInnerHTML={{
                                __html: stats.description,
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

const QuoteItem = ({ authorName, authorRole, quote }) => {
    return (
        <div className={style.quoteWrapper}>
            <div className={style.quote}>
                <div className={style.quoteTitle}>{quote}</div>
                <div className={style.quoteRow}>
                    <div className={style.quoteAuthor}>
                        <div className={style.quoteAuthorName}>{authorName}</div>
                        <div className={style.quoteAuthorRole}>{authorRole}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Article = data => {
    const { title, header, leftContents, rightContents } = data;
    const containerRef = useRef(null);
    const idGenerator = hyperid();
    header.share = {
        title: 'SHARE',
        items: [
            {
                title: 'twitter',
                icon: 'twitter2',
            },
            {
                title: 'linkedin',
                icon: 'linkedin2',
            },
        ],
    };
    header.date = header.publishingDate;

    const BiographyList = ({ title, biographies: items }) => {
        const accordions = items.map(item => {
            const title = BiographyItem(item);
            return {
                title,
                content: item.bodyCopy,
                readLabelOpen: 'BIO',
                readLabelClosed: 'BIO',
            };
        });
        return (
            <div className={style.biographyList}>
                <h4 className={style.title}>{title}</h4>
                <div className={style.biographyItems}>
                    <AccordionList className={style.accordionList} accordions={accordions} noPadding={true} />
                </div>
            </div>
        );
    };

    const BiographyItem = item => {
        const bioItemRef = useRef(null);
        if (!item.id) {
            item.id = idGenerator();
        }
        return (
            <>
                <div className={[style.biographyItem].join(' ')} key={item.id}>
                    <div className={style.biographyImageWrapper} ref={bioItemRef}>
                        <img className={style.biographyImage} src={item?.image?.url || '/images/bioImageDefault.jpg'} />
                    </div>
                    <div className={style.biographyAuthor}>
                        <div className={style.biographyName}>{item?.name}</div>
                        <div className={style.biographyRole}>{item?.role}</div>
                    </div>
                </div>
            </>
        );
    };

    const TextFormat = content => {
        return (
            <div className={style.articleText}>
                <h4>{content?.title}</h4>
                <div
                    className={style.articleDiv}
                    dangerouslySetInnerHTML={{
                        __html: content?.body,
                    }}
                ></div>
            </div>
        );
    };

    const renderChildComponent = component => {
        const { type } = component;
        switch (type) {
            case 'insightsBiographyList':
                return BiographyList(component);
            case 'insightsPodcastWidgetItem':
                return <div className={style.podcast} dangerouslySetInnerHTML={{ __html: component.codeSnippet }} />;
            case 'newsDetailText':
                return TextFormat(component);
            case 'insightsQuoteItem':
                return QuoteItem(component);
            case 'statementsAndStats':
                return StatsComponent({
                    ...component,
                });
            default:
                return '';
        }
    };

    const [currentUrl, setCurrentUrl] = useState('');
    const [sharethis, setSharethis] = useState('none');

    useEffect(() => {
        const cookieAccept = getCookieConsentValue();
        setSharethis(cookieAccept === 'false' || cookieAccept === undefined ? 'none' : 'block');
        setCurrentUrl(window.location.href);
        if (window.__sharethis__ && getCookieConsentValue() === 'true') {
            window.__sharethis__.initialize();
        }
    }, []);

    const shareContent = {};

    return (
        <div className={style.el} ref={containerRef}>
            <div className={[style.container, 'container'].join(' ')}>
                <NewsDetailHeader
                    header={header}
                    sharethis={sharethis}
                    content={shareContent}
                    currentUrl={currentUrl}
                />
                <div className={style.twoColumnsWrapper}>
                    <div className={style.leftColumn}>
                        <h1>{title}</h1>
                        {leftContents.map(content => renderChildComponent(content))}
                    </div>
                    <div className={style.rightColumn}>
                        {rightContents.map(content => renderChildComponent(content))}
                    </div>
                </div>
            </div>
        </div>
    );
};

Article.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    header: PropTypes.string,
    contentsInner: PropTypes.array,
    contents: PropTypes.array,
    portfolio: PropTypes.object,
    biographies: PropTypes.array,
};

StatsComponent.propTypes = {
    stats: PropTypes.array,
    title: PropTypes.string,
};

QuoteItem.propTypes = {
    authorName: PropTypes.string,
    authorRole: PropTypes.string,
    quote: PropTypes.string,
};

export default Article;
