// CONSIDER FOR DELETION, DOESN'T LOOK TO BE USED

import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import TwoColumnsHeroSectionLeft from '../TwoColumnsHeroSectionLeft';
import TwoColumnsHeroSectionRight from '../TwoColumnsHeroSectionRight';
import style from './TwoColumnsContenBlockCarousel.module.scss';

const TwoColumnsContentBlockCarousel = props => {
    const { contents, isMobile } = props;
    const sliderEl = useRef(null);

    const sliderParams = {
        slidesToShow: 1,
        arrows: false,
        infinite: false,
        speed: 700,
        draggable: true,
        swipe: true,
        variableWidth: false,
        customPaging: function (i) {
            return (
                <button className={style.pager} key={i}>
                    <span className={[style.bar, 'bar'].join(' ')}></span>
                </button>
            );
        },
        dots: true,
        dotsClass: 'slick-dots slick-bars',
    };

    return (
        <>
            {contents.length > 0 && (
                <div className={[style.el, 'two-col-list'].join(' ')}>
                    <div className={style.content}>
                        <div className={style.items}>
                            <Slider {...sliderParams} ref={sliderEl}>
                                {contents &&
                                    contents.map((item, id) => {
                                        const backgroundColor =
                                            item.backgroundColour === 'white'
                                                ? style.white
                                                : item.backgroundColour === 'grey'
                                                ? style.grey
                                                : '';
                                        if (item.imageOrientation === 'Left') {
                                            return (
                                                <div className={[style.image, backgroundColor].join(' ')} key={id}>
                                                    <div className={[style.container, 'container'].join(' ')}>
                                                        <TwoColumnsHeroSectionLeft
                                                            title={item.title}
                                                            description={item.description}
                                                            image={item.image}
                                                            video={item.video}
                                                            coverImage={item.coverImage}
                                                            stats={item.stats}
                                                            cta={item.cta}
                                                            isMobile={isMobile}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className={[style.image, backgroundColor].join(' ')} key={id}>
                                                    <div className={[style.container, 'container'].join(' ')}>
                                                        <TwoColumnsHeroSectionRight
                                                            title={item.title}
                                                            description={item.description}
                                                            image={item.image}
                                                            video={item.video}
                                                            coverImage={item.coverImage}
                                                            stats={item.stats}
                                                            cta={item.cta}
                                                            isMobile={isMobile}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                            </Slider>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

TwoColumnsContentBlockCarousel.propTypes = {
    title: PropTypes.any,
    description: PropTypes.string,
    contents: PropTypes.array,
    isMobile: PropTypes.bool,
};
export default TwoColumnsContentBlockCarousel;

TwoColumnsContentBlockCarousel.defaultProps = {};
