// CONSIDER FOR DELETION, DOESN'T LOOK TO BE USED

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import style from './ThreeCardsCarouselPopup.module.scss';

import Slider from 'react-slick';

const ThreeCardsCarouselPopup = props => {
    const { content, headline, button } = props;
    const [isHovering, setHovering] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupClose, setIsPopupClose] = useState(false);
    const [popupDisplay, setPopupDisplay] = useState(false);
    const [currntOrder, setCurrentOrder] = useState(null);
    const [slideOrder, setSlideOrder] = useState(null);
    const slideEl = useRef(null);

    const onMouseEnter = order => {
        setHovering(true);
        setCurrentOrder(order);
    };
    const onMouseLeave = () => {
        if (!popupDisplay) {
            setHovering(false);
            setCurrentOrder(null);
        }
    };
    const openPopup = order => {
        setPopupDisplay(true);
        setCurrentOrder(order);
        setTimeout(() => {
            if (slideEl) slideEl.current.slickGoTo(order);
            setSlideOrder(order);
            setIsPopupOpen(true);
            onMouseLeave();
        }, 300);
    };

    const closePopup = () => {
        setIsPopupClose(true);
        setTimeout(() => {
            setPopupDisplay(false);
        }, 700);
        setTimeout(() => {
            setIsPopupClose(false);
            setIsPopupOpen(false);
        }, 800);
    };

    const sliderParams = {
        width: '100%',
        height: '100%',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        autoplay: false,
        speed: 400,
        cssEase: 'cubic-bezier(0.25,0.1,0.25,1)',
        draggable: false,
        swipe: true,
        afterChange: current => setSlideOrder(current),
    };

    const prev = () => {
        if (slideEl) slideEl.current.slickPrev();
    };

    const next = () => {
        if (slideEl) slideEl.current.slickNext();
    };

    const changeSilid = order => {
        if (slideEl) slideEl.current.slickGoTo(order);
    };

    useEffect(() => {
        function setWH () {
            const wh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            document.documentElement.style.setProperty('--vh', `${wh}px`);
        }
        window.addEventListener('resize', setWH);
        setWH();
        return () => {
            // window.removeEventListerner('resize', setWH);
        };
    });

    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <h2 className={style.headline}>{headline}</h2>
                <div className={[style.content, isHovering ? style.hover : null].join(' ')} onMouseLeave={onMouseLeave}>
                    {content &&
                        content.map((item, i) => {
                            return (
                                <div
                                    className={[
                                        style.item,
                                        isHovering && currntOrder === i ? style.active : style.scale,
                                    ].join(' ')}
                                    key={i}
                                    onMouseEnter={() => onMouseEnter(i)}
                                    onClick={() => openPopup(i)}
                                >
                                    <div className={['h3', style.title].join(' ')}>{item.title}</div>
                                    <div className={style.button}>
                                        {button.text}
                                        <span className='icon-arrow-right' />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            {popupDisplay && (
                <div
                    className={[
                        style.popup,
                        isPopupOpen ? style.isPopupOpen : null,
                        isPopupClose ? style.isPopupClose : null,
                    ].join(' ')}
                >
                    <div className={style.background} onClick={closePopup} />
                    <div className={style.wrapper}>
                        <div className={style.main}>
                            <div className={style.header}>
                                <h2 className={style.headline}>{headline}</h2>
                                <div className={style.close} onClick={closePopup} />
                            </div>
                            {content && (
                                <div className={style.content}>
                                    <div className={style.navigate}>
                                        {content.map((item, i) => {
                                            return (
                                                <div
                                                    className={[
                                                        style['navigate-item'],
                                                        slideOrder === i ? style.active : null,
                                                    ].join(' ')}
                                                    onClick={() => changeSilid(i)}
                                                    key={i}
                                                />
                                            );
                                        })}
                                    </div>
                                    <div className={style.carousel}>
                                        <Slider {...sliderParams} ref={slideEl}>
                                            {content.map((item, i) => {
                                                return (
                                                    <div className={style['carousel-item']} key={i}>
                                                        <div className={style.title}>{item.title}</div>
                                                        <p className={style.desc}>{item.desc}</p>
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={style.bottom}>
                            <button
                                type='button'
                                className={[style.button, style.prev, slideOrder === 0 ? style.hide : null].join(' ')}
                                onClick={prev}
                            >
                                <span className='icon-arrow-right' aria-hidden='true' />
                                <span className='sr-only'>previous</span>
                            </button>
                            <button
                                type='button'
                                className={[
                                    style.button,
                                    style.next,
                                    slideOrder === content.length - 1 ? style.hide : null,
                                ].join(' ')}
                                onClick={next}
                            >
                                <span className='icon-arrow-right' aria-hidden='true' />
                                <span className='sr-only'>next</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

ThreeCardsCarouselPopup.propTypes = {
    content: PropTypes.array,
    headline: PropTypes.string,
    button: PropTypes.object,
};
export default ThreeCardsCarouselPopup;

ThreeCardsCarouselPopup.defaultProps = {};
