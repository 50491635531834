import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccordionIconItem from '../AccordionIconItem';

import style from './AccordionIconList.module.scss';

const AccordionIconList = ({ accordions }) => {
    const [selectedChild, setSelectedChild] = useState(null);
    const updateSelectedChild = (index) => {
        if (index === selectedChild) setSelectedChild(null);
        else setSelectedChild(index);
    }

    return (
        <div className={[style.el].join(' ')}>
            <div className={style.AccodionIconContainer}>
                {accordions?.map((accordion, index) => (
                    <AccordionIconItem key={index} index={index} {...accordion} selectedChild={selectedChild} updateSelectedChild={updateSelectedChild} />
                ))}
            </div>
        </div>
    );
};

AccordionIconList.propTypes = {
    accordions: PropTypes.array,
    noPadding: PropTypes.bool,
};
export default AccordionIconList;
