import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';

import Player from '../Player';
import style from './TwoColsMedia.module.scss';

import { useDispatch } from 'react-redux';

import { openPlayerModal, updatePlayerLink, updatePlayerPoster } from '../../redux/modal/playerModal.action';
import Image from 'next/image';

const TwoColsMedia = props => {
    const { content, isMobile } = props;
    const dispatch = useDispatch();

    const openPlayer = (link, poster) => {
        dispatch(openPlayerModal());
        dispatch(updatePlayerLink(link));
        dispatch(updatePlayerPoster(poster));
    };

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const videoOnPlay = () => {
        setIsVideoPlaying(true);
    };

    const isDesktop = useMediaQuery('only screen and (min-width: 650px)');

    return (
        <div className={[style.el, 'two-cols-media'].join(' ')}>
            {content &&
                content.map((item, i) => {
                    const useMobileImage = item.mobileImage?.url.length > 1 ? item.mobileImage.url : item.image?.url;
                    return (
                        <div
                            className={[
                                style.container,
                                'container',
                                item.isMediaLeft ? style.isMediaLeft : null,
                                item.quote ? style.quoteContainer : null,
                                content.length === 1 ? style.onlyOne : null,
                            ].join(' ')}
                            key={i}
                        >
                            <div className={style.media}>
                                {item.image && !item.video && (
                                    <div className={style.banner}>
                                        <div className={style.imageWrapper}>
                                            <Image src={isDesktop ? item.image.url : useMobileImage} alt="" layout="fill" />
                                        </div>
                                    </div>
                                )}
                                {item.video && (
                                    <>
                                        {isMobile ? (
                                            <div className={style.banner}>
                                                <div key='mobile-video' className={style.videoWrapper}>
                                                    <Player
                                                        link={item.video}
                                                        poster={item.poster}
                                                        isAutoPlay={false}
                                                        onPlay={videoOnPlay}
                                                    />
                                                    {!isVideoPlaying && (
                                                        <div className={style.videoPoster}>
                                                            <div className={style.imageWrapper}>
                                                                <Image src={isDesktop ? item.image.url : useMobileImage} alt="" layout="fill" />
                                                            </div>
                                                            <button type='button' className={style.playButton}>
                                                                <img src='/images/people/play.png' alt='play' />
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div key='desktop-video' className={style.player}>
                                                <div
                                                    className={style.banner}
                                                    style={{
                                                        backgroundImage: `url(${
                                                            isDesktop ? item.image.url : useMobileImage
                                                        })`,
                                                        backgroundSize: 'cover',
                                                    }}
                                                />
                                                <div className={style.imageWrapper}>
                                                    <Image src={isDesktop ? item.image.url : useMobileImage} alt="" layout="fill" />
                                                </div>
                                                <div
                                                    className={style.playButton}
                                                    onClick={() => openPlayer(item.video, item.poster)}
                                                >
                                                    <img src='/images/people/play.png' alt='play' />
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div
                                className={[style.text, item.video || item.vimeoId ? style.videoText : null].join(' ')}
                            >
                                {item?.title && (
                                    <h2
                                        className={style.title}
                                        dangerouslySetInnerHTML={{
                                            __html: item.title,
                                        }}
                                    />
                                )}
                                {item?.description && (
                                    <div
                                        className={style.desc}
                                        dangerouslySetInnerHTML={{
                                            __html: item.description,
                                        }}
                                    />
                                )}
                                {item?.quote && (
                                    <div className={style.quote}>
                                        <img className={style.icon} src='/images/people/quote.svg' />
                                        <div
                                            className={['h2', style.headline].join(' ')}
                                            dangerouslySetInnerHTML={{
                                                __html: item.quote.headline,
                                            }}
                                        />
                                        <p
                                            className={style.name}
                                            dangerouslySetInnerHTML={{
                                                __html: item.quote.name,
                                            }}
                                        />
                                        <p
                                            className={style.job}
                                            dangerouslySetInnerHTML={{
                                                __html: item.quote.title,
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

TwoColsMedia.propTypes = {
    content: PropTypes.array,
    isMobile: PropTypes.bool,
};
export default TwoColsMedia;

TwoColsMedia.defaultProps = {};
