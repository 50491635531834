import React from 'react';
import PropTypes from 'prop-types';

import { SmartLink } from '../SmartLink';

import style from './InTheNewsCard.module.scss';

const InTheNewsCard = ({
    author,
    title,
    summary,
    publicationName,
    filters,
    image,
    publishingDate,
    articleType,
    cta,
    type
}) => {
    const filterNames = filters
        ?.map(type => type.value.map(value => [type.type, value.value].join('-')).join(' '))
        .join(' ');

    const ctaPagePath = cta?.link.url.replace(/\/$/, '');

    const hasImage = image && image.url !== '';

    return (
        <SmartLink href={ctaPagePath} as={cta?.link.url} className={[style.el, filterNames].join(' ')}>
            <div className={[style.content, hasImage ? '' : style.noImage].join(' ')}>
                {hasImage && (
                    <div className={style.wrapper}>
                        <div className={style.image}>
                            <img src={image?.url} alt={image?.alt} />
                        </div>
                    </div>
                )}
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.type}>{type}</div>
                        <div className={style.date}>{publishingDate}</div>
                    </div>
                    <h4 className={style.title}>{title}</h4>
                    <div className={style.publicationName}>{publicationName}</div>
                    <div className={style.summary}>{summary}</div>
                </div>
            </div>
        </SmartLink>
    );
};

InTheNewsCard.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    publicationName: PropTypes.string,
    link: PropTypes.object,
    filters: PropTypes.array,
    selectedFilters: PropTypes.array,
    image: PropTypes.object,
    author: PropTypes.object,
    publishingDate: PropTypes.string,
    articleType: PropTypes.string,
    cta: PropTypes.object,
    type: PropTypes.string,
};

export default InTheNewsCard;
