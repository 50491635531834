import React from 'react';
import PropTypes from 'prop-types';
import style from './RecentInvestment.module.scss';
import { useRouter } from 'next/router';

import PortfolioCard from '../Cards/portfolioCard';
import CarouselStandard from '../CarouselStandard';

const RecentInvestment = props => {
    const { title, items, cta } = props;
    const router = useRouter();
    const isPeople = router.asPath.indexOf('people') >= 0;

    const sliderBreakpoints = [
        {
            breakpoint: 9999,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1360,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 599,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 460,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ];

    const sliderParams = {
        slidesToShow: 1,
        slidesToScroll: 4,
        arrows: false,
        infinite: false,
        speed: 700,
        draggable: true,
        swipe: true,
        variableWidth: true,
        responsive: sliderBreakpoints
    };

    return (
        items.length > 0 && (
            <div
                className={[style.el, 'recent-investments', isPeople ? style.people : null].join(' ')}
                style={cta.link.url.length <= 0 ? { borderBottom: '1px solid #C9C9D7' } : {}}
            >
                <CarouselStandard className={style.carouselOverrides} title={title} cta={cta} sliderParams={sliderParams} sliderBreakpoints={sliderBreakpoints}>
                    {items &&
                        items
                            .filter((_item, i) => i < 12)
                            .map(({ icon, name, link, country, copies }, id) => (
                                <div className={style.image} key={id}>
                                    <div className={style.portfolioCard}>
                                        <PortfolioCard
                                            name={name}
                                            description={country ? copies.countryLabel + ' ' + country : ''}
                                            icon={icon}
                                            cta={{ link: link, text: copies.findOutMore }}
                                        />
                                    </div>
                                </div>
                            ))}
                </CarouselStandard>
            </div>
        )
    );
};

RecentInvestment.propTypes = {
    title: PropTypes.any,
    items: PropTypes.array,
    cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.object,
    }),
};
export default RecentInvestment;

RecentInvestment.defaultProps = {};
