import React, { useState } from 'react';
import PropTypes from 'prop-types';

import style from './TwoColumnsHeroSectionRight.module.scss';
import { useDispatch } from 'react-redux';

import { openPlayerModal, updatePlayerLink } from '../../redux/modal/playerModal.action';

import Player from '../Player';
import { SmartLink } from '../SmartLink';

const TwoColumnsHeroSectionRight = props => {
    const { title, cta, description, image, video, isMobile, coverImage, stats } = props;

    const dispatch = useDispatch();

    const openPlayer = link => {
        dispatch(openPlayerModal());
        dispatch(updatePlayerLink(link));
    };

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const videoOnPlay = () => {
        setIsVideoPlaying(true);
    };

    const componentImageURL = video ? coverImage?.url : image.url;

    return (
        <div className={style.el}>
            <div className={style.content}>
                <div className={[style.container, 'container'].join(' ')}>
                    <div className={style.contentWrapper}>
                        <h2 className={style.title} dangerouslySetInnerHTML={{ __html: title }} />
                        <div className={style.description} dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
                </div>
                {stats && stats.length > 0 && (
                    <div className={style.stats}>
                        {stats.map((stats, id) => (
                            <div className={style.statsInfo} key={id}>
                                <h3 className={style.statsTitle}>{stats.title}</h3>
                                <div
                                    className={style.statsDesc}
                                    dangerouslySetInnerHTML={{ __html: stats.description }}
                                ></div>
                            </div>
                        ))}
                    </div>
                )}
                {cta && cta.link && cta.link.url && (
                    <div className={style.cta}>
                        <div className={style.ctaLink}>
                            <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                                {cta.text}
                            </SmartLink>
                        </div>
                    </div>
                )}
            </div>
            <div
                className={[style.image, video ? style.video : ''].join(' ')}
                style={{
                    backgroundImage: video && isMobile ? '' : `url(${componentImageURL})`,
                }}
            >
                {video && (
                    <>
                        {isMobile ? (
                            <div key='mobile-video' className={style.videoWrapper}>
                                <Player link={video} poster={coverImage?.url} isAutoPlay={false} onPlay={videoOnPlay} />
                                {!isVideoPlaying && (
                                    <div
                                        className={style.videoPoster}
                                        style={{
                                            backgroundImage: `url(${coverImage?.url})`,
                                        }}
                                    >
                                        <button type='button' className={style.playButton}>
                                            <img src='/images/people/play.png' alt='play' />
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <button
                                key='desktop-video'
                                type='button'
                                className={style.playButton}
                                onClick={() => openPlayer(video, image?.url)}
                            >
                                <img src='/images/people/play.png' alt='play' />
                            </button>
                        )}
                    </>
                )}

                {/* <div className={style.overlay}>
                    <OverlayImage />
                </div> */}
            </div>
        </div>
    );
};

TwoColumnsHeroSectionRight.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    coverImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    video: PropTypes.string,
    isMobile: PropTypes.bool,
    stats: PropTypes.array,
    cta: PropTypes.object,
};
export default TwoColumnsHeroSectionRight;

TwoColumnsHeroSectionRight.defaultProps = {
    title: 'Heading',
    description: 'Descriptions',
    image: {
        url: 'https://via.placeholder.com/900x900.jpg',
        alt: 'image-1',
    },
};
