import React from 'react';
import style from './LatestNews.module.scss';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';

const LatestNews = (props) => {
    const { title: newsTitle, featuresItem, items } = props;
    const { image, title, author, cta, publishingDate, type, articleType } = featuresItem;

    const isMobile = useMediaQuery('only screen and (max-width: 650px)');

    const sortDate = (a, b) => {
        return new Date(b.publishingDate) - new Date(a.publishingDate);
    };

    return (
        <>
            {image ? (
                <>
                    <div className={style.newsHeader}>
                        <h2>{newsTitle}</h2>
                    </div>
                    <section className={style.newsSection}>
                        <div className={[style.container, 'container'].join(' ')}>
                            <div className={style.newsWrapper}>
                                <a className={style.primaryItem} href={cta.link.url}>
                                    <div className={style.featuresNews}>
                                        <div className={style.newsTagsandDate}>
                                            <span className={style.newsType}>{type}</span>
                                            <span className={style.newsDate}>{publishingDate}</span>
                                        </div>
                                        <div className={style.featuresTitle}>
                                            <h4>{title}</h4>
                                        </div>
                                        {author.name && (
                                            <div className={style.newsAuthor}>
                                                <span>
                                                    <img src={author.image.url} alt={author.image.alt} />
                                                </span>
                                                <span className={style.newsAuthorName}>by {author.name}</span>
                                            </div>
                                        )}
                                        <div className={style.newsImage}>
                                            <img src={image.url} alt={image.alt} />
                                            {articleType === 'video' && (
                                                <img className={style.playIcon} src="/images/people/play.png" alt="" />
                                            )}
                                        </div>
                                        <div className={style.featuresCardCta}>
                                            {cta.text}
                                        </div>
                                    </div>
                                </a>

                                <div className={style.newsSmallTiles}>
                                    {items
                                        ? items
                                              .sort(sortDate)
                                              .filter((_item, i) => i < 6)
                                              .map(
                                                  ({ cta, image, publishingDate, title, type, articleType }, index) => (
                                                      <div key={index} className={style.newsTileWrapper}>
                                                          <a href={cta.link.url}>
                                                              <div className={style.newsInnerCard}>
                                                                  <div className={style.newsTagsandDate}>
                                                                      <span className={style.newsType}>{type}</span>
                                                                      <span className={style.newsDate}>
                                                                          {publishingDate}
                                                                      </span>
                                                                  </div>
                                                                  <div className={style.featuresTitle}>
                                                                      <p>{title}</p>
                                                                  </div>

                                                                  <div className={style.newsImage}>
                                                                      {isMobile ? (
                                                                          <img src={image.url} alt={image.alt} />
                                                                      ) : (
                                                                          <img src={image.url} alt={image.alt} />
                                                                      )}
                                                                      {articleType === 'video' && (
                                                                          <img
                                                                              className={style.playIcon}
                                                                              src="/images/people/play.png"
                                                                              alt=""
                                                                          />
                                                                      )}
                                                                  </div>
                                                                  {!isMobile && (
                                                                      <div className={style.newsCardCta}>
                                                                          {cta.text}
                                                                      </div>
                                                                  )}
                                                              </div>
                                                          </a>
                                                      </div>
                                                  )
                                              )
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                ' '
            )}
        </>
    );
};

LatestNews.propTypes = {
    title: PropTypes.string,
    featuresItem: PropTypes.shape({
        title: PropTypes.string,
        articleType: PropTypes.string,
        author: PropTypes.shape({
            name: PropTypes.string,
            image: PropTypes.object,
        }),
        image: PropTypes.shape({
            url: PropTypes.string,
            alt: PropTypes.string,
        }),
        cta: PropTypes.shape({
            text: PropTypes.string,
            link: PropTypes.shape({
                target: PropTypes.string,
                url: PropTypes.string,
            }),
        }),
        publishingDate: PropTypes.string,
        type: PropTypes.string,
    }),
    items: PropTypes.array,
};

export default LatestNews;
