import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import style from './OfficeDetail.module.scss';
import { SmartLink } from '../SmartLink';
import RecentInvestment from '../RecentInvestment';
import PeopleListing from '../PeopleListing';
import PinIcon from '../Icons/PinIcon';
import ContactIcon from '../Icons/ContactIcon';

const OfficeDetail = props => {
    const {
        name,
        subTitle,
        // region,
        address,
        tel,
        description,
        map: mapCoordinates,
        team,
        investments,
        googleMapKey,
    } = props;

    const center = mapCoordinates;

    const containerStyle = {
        width: '100%',
        height: '719px',
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleMapKey,
    });

    const onLoad = useCallback(function callback (map) {
        const adjust = 0.001;
        const sw = { lng: center.lng - adjust, lat: center.lat - adjust };
        const ne = { lng: center.lng + adjust, lat: center.lat + adjust };
        const bounds = new window.google.maps.LatLngBounds(sw, ne);
        map.fitBounds(bounds);
    }, []);

    return (
        <div className={style.el}>
            <div className={style.addressContainer}>
                <div className={style.addressWrapper}>
                    <div className={style.addressDetails}>
                        <div className={[style.container, 'container'].join(' ')}>
                            <div className={style.addressGroup}>
                                <h2 className={style.addressName}>{name}</h2>
                                <h2 className={style.subTitle}>{subTitle}</h2>
                            </div>
                            <div className={style.addressContact}>
                                <div className={style.address}>
                                    <PinIcon />
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: address,
                                        }}
                                    />
                                </div>
                                {tel && (
                                    <div className={style.tel}>
                                        <ContactIcon />
                                        <span>
                                            Tel: <a href={`tel:${tel}`}>{tel}</a>
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className={style.addressDesc}>
                                <h4>{description}</h4>
                            </div>
                        </div>
                    </div>
                    <div className={style.addressMap}>
                        {isLoaded && (
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={16}
                                onLoad={onLoad}
                            >
                                <Marker position={center} />
                            </GoogleMap>
                        )}
                    </div>
                </div>
            </div>
            {team.peopleListing && (
                <section className={style.teamWrapper}>
                    <div className={[style.container, 'container'].join(' ')}>
                        <div className={style.teamName}>
                            <h2>{team.title}</h2>
                        </div>
                    </div>
                    <PeopleListing {...team.peopleListing} hiddenFilters={['office']} />
                    <div className={[style.container, 'container'].join(' ')}>
                        <div className={style.meetLinkCta}>
                            <div className={style.meetLink}>
                                <SmartLink className='arrow-link' hasArrow href={team.cta.link.url}>
                                    {team.cta.text}
                                </SmartLink>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <RecentInvestment {...investments} />
            {/* <div>
                <div onClick={scrollToTop} className={style.loader}
                    style={{ display: visible ? 'inline' : 'none' }}>scroll to top</div>
            </div> */}
        </div>
    );
};

OfficeDetail.propTypes = {
    name: PropTypes.string,
    subTitle: PropTypes.string,
    region: PropTypes.string,
    address: PropTypes.string,
    tel: PropTypes.string,
    description: PropTypes.string,
    map: PropTypes.shape({
        lng: PropTypes.number,
        lat: PropTypes.number,
    }),
    team: PropTypes.object,
    investments: PropTypes.object,

    title: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    mobileImage: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
    }),
    contents: PropTypes.any,
    googleMapKey: PropTypes.string,
};
export default OfficeDetail;
