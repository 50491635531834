import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import style from './AccordionItem.module.scss';

const AccordionItem = ({ content, title, readLabelOpen, readLabelClosed, noPadding }) => {
    const contentRef = useRef(null);
    const contentInnerRef = useRef(null);

    if (!readLabelOpen) {
        readLabelOpen = 'READ LESS';
    }
    if (!readLabelClosed) {
        readLabelClosed = 'READ MORE';
    }

    const [openState, setOpenState] = useState(false);
    const toggleOpenState = () => {
        if (!openState) {
            const rect = contentInnerRef.current.getBoundingClientRect();
            contentRef.current.style.maxHeight = rect.height + 'px';
        } else {
            contentRef.current.style.maxHeight = '0px';
        }

        setOpenState(!openState);
    };

    const arrowClass = () => {
        return 'icon-arrow-up ' + style['icon-arrow-black'] + ' ' + (openState ? style.arrowDown : style.arrowUp);
    };

    return (
        <div className={[style.el, noPadding ? style.elNoPadding : ''].join(' ')} data-open={openState}>
            <div className={style.launcher} onClick={toggleOpenState}>
                <div className={style.title}>
                    <div className={style.label}>{title}</div>
                    <div className={style.arrow}>
                        {openState ? readLabelOpen : readLabelClosed} <span className={arrowClass()}></span>
                    </div>
                </div>
                <div className={style.content} ref={contentRef}>
                    <div
                        className={style.contentInner}
                        dangerouslySetInnerHTML={{ __html: content }}
                        ref={contentInnerRef}
                    ></div>
                </div>
            </div>
        </div>
    );
};

AccordionItem.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    readLabelOpen: PropTypes.string,
    readLabelClosed: PropTypes.string,
    noPadding: PropTypes.bool,
};
export default AccordionItem;

AccordionItem.defaultProps = {
    title: 'Heading',
    content: 'Content',
};
