import React from 'react';
import PropTypes from 'prop-types';
import style from './StatsItem.module.scss';
import CountingNumbers from '../CountingNumbers';

const StatsItem = props => {
    const { stats } = props;

    return (
        <div className={style.el}>
            {stats.map((stats, id) => (
                <div className={style.statsInfo} key={id}>
                    <h3 className={style.statsTitle}><CountingNumbers value={stats.title} /></h3>
                    <div
                        className={style.statsDesc}
                        dangerouslySetInnerHTML={{ __html: stats.description }}
                    ></div>
                </div>
            ))}
        </div>
    );
};

StatsItem.propTypes = {
    title: PropTypes.string,
    stats: PropTypes.array
};

export default StatsItem;
