import React from 'react';
import PropTypes from 'prop-types';

import GovernanceListing from '../GovernanceListing';
import RichTextContents from '../RichTextContents';

const StaticPageContent = props => {
    const { useExpandableSectionsLayout } = props;

    if (useExpandableSectionsLayout) {
        return <GovernanceListing {...props} />;
    }

    return <RichTextContents {...props} />;
};

StaticPageContent.propTypes = {
    useExpandableSectionsLayout: PropTypes.bool,
    title: PropTypes.string,
    contents: PropTypes.array,
};

export default StaticPageContent;
