import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import style from './GovernanceListing.module.scss';

const GovernanceListing = props => {
    const { title, contents } = props;
    const [openSections, setOpenSections] = useState([]);

    /* const [setHash] = useState('');
    useEffect(() => {
        setHash(location.hash);
    }); */

    const prepareTitle = (title) => {
        return title.replace(/\//g, '').toLowerCase();
    }

    const expandSection = (itemTitle) => {
        itemTitle = prepareTitle(itemTitle);
        if (openSections.includes(itemTitle)) setOpenSections([...openSections.filter(i => i !== itemTitle)]);
        else setOpenSections([...openSections, itemTitle]);
    }

    const isOpen = (itemTitle) => {
        itemTitle = prepareTitle(itemTitle);
        return openSections.includes(itemTitle);
    }

    useEffect(() => {
        const firstItem = contents.find(i => ['staticPageContentAnchor', 'staticPageContentTextContent'].includes(i.type) && i.title);
        expandSection(firstItem.title);
    }, []);

    return (
        <section className={style.section}>
            <div className={[style.container, 'container'].join(' ')}>
                {title && <h1>{title}</h1>}
                {contents.map((item, j) => {
                    let markup;

                    if (item.type === 'staticPageContentLinebreak') {
                        markup = <div className={style.section__linebreak} key={j} />;
                    }

                    if (item.type === 'staticPageContentAnchor') {
                        markup = (
                            <div
                                className={[style.section__title, item.indent ? style.indent : '', isOpen(item.title) ? style.open : ''].join(' ')}
                                id={item.id.replace(/\s+/g, '-')}
                                key={j}
                                onClick={() => expandSection(item.title)}
                            >
                                <h2 className={style.titleText}>{item.title}</h2>
                                <span className={style.afterArrow + ' icon-arrow-up'}></span>
                            </div>
                        );
                    }

                    if (item.type === 'staticPageContentTextContent') {
                        markup = (
                            <>
                                {item.title && item.id && item.title.length > 0 && item.id.length > 0 && (
                                    <div
                                        className={[style.section__title, item.indent ? style.indent : '', isOpen(item.title) ? style.open : ''].join(
                                            ' '
                                        )}
                                        id={item.id.replace(/\s+/g, '-')}
                                        key={j}
                                        onClick={() => expandSection(item.title)}
                                    >
                                        <h2 className={style.titleText}>{item.title}</h2>
                                        <span className={style.afterArrow + ' icon-arrow-up'}></span>
                                    </div>
                                )}
                                <div
                                    className={[style.section__desc, item.indent ? style.indent : '', isOpen(item.title) ? style.open : ''].join(' ')}
                                    dangerouslySetInnerHTML={{
                                        __html: item.body,
                                    }}
                                />
                            </>
                        );
                    }

                    return markup;
                })}
            </div>
        </section>
    );
};

GovernanceListing.propTypes = {
    title: PropTypes.string,
    menu: PropTypes.array,
    contents: PropTypes.array,
};

export default GovernanceListing;
