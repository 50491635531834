import React from 'react';
import PropTypes from 'prop-types';
import AccordionItem from '../AccordionItem';

import style from './AccordionList.module.scss';

const AccordionList = ({ accordions, noPadding }) => {
    return (
        <div className={[style.el, noPadding ? style.elNoPadding : ''].join(' ')}>
            {accordions?.map((accordion, index) => (
                <AccordionItem key={index} {...accordion} noPadding={noPadding} />
            ))}
        </div>
    );
};

AccordionList.propTypes = {
    accordions: PropTypes.array,
    noPadding: PropTypes.bool,
};
export default AccordionList;
