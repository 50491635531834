import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import ReportCard from '../Cards/reportCard';
import { SmartLink } from '../SmartLink';
import style from './InvResReport.module.scss';
import { removeQuery } from '../../utils/queryString';

const InvResReport = props => {
    const { title, description, report, cta, popup } = props;
    const router = useRouter();
    let link = '';
    if (cta && cta.link && cta.link.url) {
        const pagePath = cta.link.url.replace(/\/$/, '');
        link = `${removeQuery(router.asPath)}?pageId=${pagePath}`;
    }

    return (
        <div className={[style.el, 'inv-res-report'].join(' ')}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.content}>
                    <h2 className={style.title}>{title}</h2>
                    <div className={style.right}>
                        <div className={style.description} dangerouslySetInnerHTML={{ __html: description }} />
                        {report && (
                            <div className={style.reportCard}>
                                <ReportCard {...report} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {cta && cta.link && cta.link.url && cta.link.url !== '' ? (
                <div className={style.cta}>
                    <div className={[style.container, 'container'].join(' ')}>
                        <div className={style.ctaLink}>
                            {popup ? (
                                <SmartLink href={link} as={cta.link.url} hasArrow className='arrow-link'>
                                    {cta.text}
                                </SmartLink>
                            ) : (
                                <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                                    {cta.text}
                                </SmartLink>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

InvResReport.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    report: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.object,
        mobileImage: PropTypes.object,
        publishingDate: PropTypes.string,
        cta: PropTypes.object,
    }),
    cta: PropTypes.object,
    popup: PropTypes.bool,
};

export default InvResReport;
