import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import CustomSelect from '../CustomSelect';

import style from './SponsorList.module.scss';

import { setQueryStringValue, removeQueryStringValue, getQueryStringValue } from '../../utils/queryString';

const SponsorList = props => {
    const { title, items: originalItems } = props;
    let { copies } = props;
    if (!copies) {
        copies = {
            sortAZLabel: 'A-Z',
            sortZALabel: 'Z-A',
            sortBy: 'Sort By',
        };
    }
    const router = useRouter();
    const [sort, setSort] = useState('a_z');
    const [items, setItems] = useState(originalItems);
    const sortSelect = [
        {
            label: copies?.sortAZLabel,
            value: 'a_z',
        },
        {
            label: copies?.sortZALabel,
            value: 'z_a',
        },
    ];

    const handleSortChange = value => {
        const val = typeof value === 'string' ? value : value.target.value;
        if (val !== sort) {
            setSort(val);
        }
    };

    const isFirstSort = useRef(true);

    useEffect(() => {
        if (isFirstSort.current) {
            isFirstSort.current = false;
            return;
        }
        const items = originalItems.sort((a, b) => {
            if (sort === 'a_z') {
                return a.title.toLowerCase().charCodeAt(0) - b.title.toLowerCase().charCodeAt(0);
            } else {
                return b.title.toLowerCase().charCodeAt(0) - a.title.toLowerCase().charCodeAt(0);
            }
        });
        setItems(items);
        if (sort === 'a_z') {
            removeQueryStringValue('sort', '', router);
        } else {
            setQueryStringValue('sort', sort, '', router);
        }
    }, [sort]);

    useEffect(() => {
        const handleRouteChange = url => {
            const queryString = url.split('?').pop();
            const sort = getQueryStringValue('sort', queryString);

            if (sort) {
                setSort(sort);
            } else {
                setSort('a_z');
            }
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    useEffect(() => {
        const sortValue = getQueryStringValue('sort', '', router);
        if (sortValue && sort !== sortValue) {
            setSort(sortValue);
        }
    }, []);

    return (
        <div className={style.el}>
            <div className={[style.container, 'container'].join(' ')}>
                <h2 className={style.headline}>{title}</h2>
                <div className={style.filtersSort}>
                    <div className={style.sortSelect}>
                        <CustomSelect
                            active={sort}
                            data={sortSelect}
                            defaultTitle={copies?.sortBy}
                            handleSelect={handleSortChange}
                        />
                    </div>
                </div>
                <div className={style.sponsors}>
                    <div className={style.wrapper}>
                        {items.map((item, i) => (
                            <div key={i} className={style.sponsorItem}>
                                <img src={item.icon.url} alt={item.icon.alt} />
                                <div dangerouslySetInnerHTML={{ __html: item?.description }}></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

SponsorList.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array,
    copies: PropTypes.object,
};

export default SponsorList;
