import React from 'react';
import PropTypes from 'prop-types';
// import { useMediaQuery } from '@react-hook/media-query';
import style from './Testimonials.module.scss';

const Card = props => {
    const { image, testimonial, name, role, isBig } = props;
    return (
        <div className={[style.card, isBig ? style.big : ''].join(' ')}>
            <div className={style.media}>
                {image && (
                    <div className={style.image}>
                        <img src={image.url} alt={name} />
                    </div>
                )}
            </div>
            <div className={style.text}>
                <div className={style.quote}>
                    {isBig && <img className={style.icon} src='/images/people/quote.svg' />}
                    <div
                        className={style.headline}
                        dangerouslySetInnerHTML={{
                            __html: testimonial,
                        }}
                    />
                    <div
                        className={style.name}
                        dangerouslySetInnerHTML={{
                            __html: name,
                        }}
                    />
                    <div
                        className={style.job}
                        dangerouslySetInnerHTML={{
                            __html: role,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

Card.propTypes = {
    testimonial: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    image: PropTypes.object,
    isBig: PropTypes.bool,
};

const Testimonials = props => {
    const { items } = props;

    return (
        <div className={[style.el, 'testimonials'].join(' ')}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.col1}>
                    {items &&
                        items.map((item, i) => {
                            if (i % 5 === 0) {
                                return <Card {...item} key={i} isBig />;
                            }
                            return '';
                        })}
                </div>
                <div className={style.col2}>
                    {items &&
                        items.map((item, i) => {
                            if (i % 5 === 1 || i % 5 === 3) {
                                return <Card {...item} key={i} />;
                            }
                            return '';
                        })}
                </div>
                <div className={style.col3}>
                    {items &&
                        items.map((item, i) => {
                            if (i % 5 === 2 || i % 5 === 4) {
                                return <Card {...item} key={i} />;
                            }
                            return '';
                        })}
                </div>
            </div>
        </div>
    );
};

Testimonials.propTypes = {
    items: PropTypes.array,
};
export default Testimonials;

Testimonials.defaultProps = {};
