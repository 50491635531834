import React from 'react';
import PropTypes from 'prop-types';
import style from './FullColumnTextBlock.module.scss';

const FullColumnTextBlock = props => {
    const { title, description, backgroundColour } = props;

    return (
        <div
            className={[
                style.el,
                'full-col-text',
                backgroundColour === 'white' ? style.white : backgroundColour === 'grey' ? style.grey : '',
            ].join(' ')}
        >
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.content}>
                    {title !== '' && <h2 className={style.title}>{title}</h2>}
                    {description !== '' && (
                        <div className={style.description} dangerouslySetInnerHTML={{ __html: description }}></div>
                    )}
                </div>
            </div>
        </div>
    );
};

FullColumnTextBlock.propTypes = {
    title: PropTypes.any,
    description: PropTypes.string,
    backgroundColour: PropTypes.string,
};
export default FullColumnTextBlock;

FullColumnTextBlock.defaultProps = {};
